.external-brand-creator-root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  .wizard-conainer {
  }
}

.brand-status-root {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #1f2023;
  color: white;
  .brand-status-content {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 12px;
    .svg-root {
      height: 50px;
      width: 100%;
      svg {
        .svgFill {
          fill: white;
        }
        .svgStroke {
          stroke: white;
        }
      }
    }
  }
}
