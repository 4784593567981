.vip-card-types-root {
    .vip-card-types-table {
        .okeokeDataTable {
            .dataColumns {
                .dataColumn {
                    .dataCell {
                        height: 4em;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}
.modal.cardType-modal {
    .modal-content {
        width: 600px;
        .modal-header {
            margin-bottom: 0;
        }
    }
}
.card-type-fields {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    .textField-root {
        margin-top: 1em;
    }
}