.reports-container {
    padding: 24px;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    .head {
        flex: 0 0 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
        .left-side {
            .title {
                font-size: 22px;
                font-weight: bold;
            }
            .sub-title {
                font-size: 14px;
                font-style: italic;
            }
        }
    }
    .body {
        flex: 1 1 auto;
        overflow: auto;
        .row {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        .box-container {
            display: flex;
            margin-bottom: 12px;
            gap: 12px;
            flex-wrap: wrap;
            .box {
                padding: 12px;
                background-color: var(--main-box-color);
                color: var(--ipanel-main-text-color);
                text-align: center;
                border-radius: 5px;
                width: 175px;
                height: 65px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                overflow: hidden;
                .label {
                    font-size: 14px;
                }
                .value {
                    font-size: 20px;
                    height: 24px;
                }
            }
        }
        .block {
            border-bottom: 1px solid var(--grey);
            width: 250px;
            padding: 8px 0;
            .block-title {
                font-weight: bold;
                font-size: 18px;
            }
            .row {
                display: flex;
                gap: 12px;
                justify-content: space-between;
                .amount {
                    text-align: right;
                }
            }
        }
        .table-container {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            flex-shrink: 0;
            width: 700px;
        }
        .chart-container {
            flex-shrink: 0;
            background-color: var(--main-box-color);
            padding: 12px;
            border-radius: 5px;
        }
    }    
}
.item-sales-container {
    .body {
        .table-container {
            width: 100%;
            .okeoke-data-table {
                max-width: 500px;
            }
        }
    }
}
.vat-sum-container {
    .table-container {
        width: 400px;
    }
    .chart-container {
        width: 430px;
        height: 200px;
    }
}
.transactions-container {
    .chart-container {
        width: 430px;
        height: 200px;
    }
}
.discounts-container {
   
}
.paymentType-container {
    .table-container {
        max-width: 800px;
    }
}
.users-container {
    .user-container {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        .user-box {
            background-color: var(--main-box-color);
            padding: 12px;
            border-radius: 5px;
            min-width: 175px;
            .user-name {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 6px;
            }
            .data-box {
                border-bottom: 1px solid var(--grey);
                padding: 6px;
                &:last-of-type {
                    border: none;
                }
                .label {
                    font-size: 14px;
                }
                .value {
                    font-size: 18px;
                }
            }
        }
    }
}
.devices-container {
    .device-container {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        .device-box {
            background-color: var(--main-box-color);
            padding: 12px;
            border-radius: 5px;
            min-width: 175px;
            .device-name {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 6px;
            }
            .data-box {
                border-bottom: 1px solid var(--grey);
                padding: 6px;
                &:last-of-type {
                    border: none;
                }
                .label {
                    font-size: 14px;
                }
                .value {
                    font-size: 18px;
                }
            }
        }
    }
}