.view-root {
    position: relative;
    padding: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.view-actions {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    min-height: 4.5em;
    padding: 0.5em 0.25em;
    overflow: auto;
    justify-content: space-between;
    .actions-left, .actions-right {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .actions-right {
        justify-content: flex-end;
    }
    .btn {
        margin: 0 0 0 1em;
    }
    .date-selector {
        display: flex;
    }
}
.view-actions::-webkit-scrollbar-thumb {
    
}
.view-actions::-webkit-scrollbar {
    height: 4px;
}
.view-actions::-webkit-scrollbar-track {
    background: transparent;
}
.view-content {
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}