.brands-editor {
  .header {
    .title {
      cursor: pointer;
    }
  }
}

.brand-dashboard-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  padding-top: 0.5em;
}

.brand-card-root {
  width: 410px;
  min-height: 120px;
  background: var(--main-box-color);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  transition: filter 200ms ease-in-out;
  padding: 12px;
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  user-select: none;
  gap: 10px;
  &:hover {
    cursor: pointer;
  }
  .header {
    display: flex;
    width: 100%;
    overflow: hidden;
    gap: 4px;
    .brand-name {
      display: flex;
      flex: 1 1 auto;
      white-space: nowrap;
      font-size: 20px;
      font-weight: bold;
      position: relative;
      overflow: clip;
    }
    svg {
      height: 30px;
      display: flex;
      min-width: 30px;
      flex: 0 0 30px;
      top: 4px;
      right: 6px;
    }
  }
  .stat-container{
    display: flex;
    gap: 6px;
    .name{
      display: flex;
      font-weight: 600;
      align-items: center;
    }
  }
  .state-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
  .tags-container {
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    align-items: flex-start;
    .tags {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 55px);
      gap: 5px;
    }
    .state-container {
      gap: unset;
      display: flex;
      width: 55px;
      justify-content: center;
      align-self: flex-end;
      text-align: center;
      &.warning {
        color: red;
      }
    }
  }
}

.view-content.selected-brand {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
  .info-box {
    width: 360px;
    padding: 1em;
    background: var(--main-box-color);
    margin: 0 1em 1em 0;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    .textField-root {
      margin: 0 1em 1em 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .image-upload-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.brand-devices-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.brand-dashboard-dataTable-root {
  width: 100%;
  height: 100%;
}

.event-box-root {
  display: flex;
  flex-direction: column;
  background-color: var(--main-box-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  width: 200px;
  .title {
  }
  .event-box {
    display: flex;
    flex-direction: column;
    .options {
      display: flex;
      justify-content: space-around;
    }
  }
}

.brand-config-root {
  display: flex;
  flex-direction: column;
  background-color: var(--main-box-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  .title {
    font-size: 18;
    font-weight: bold;
  }
  .event-boxes {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
  }
}

.global-brands-base-root {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .info-box {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
}

.global-brands-brandDetails-root {
  display: flex;
  flex-direction: column;
  background-color: var(--main-box-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .details-container {
    display: flex;
    gap: 8px;
  }
  .tags-version-container {
    display: flex;
    .tags-container {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 40px);
      gap: 8px;
    }
    .version {
      display: flex;
      width: 40px;
      align-self: flex-end;
      justify-content: flex-end;
    }
  }
  .app-links-context {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .app-links-container {
      display: flex;
      gap: 8px;
      .btn {
        color: white;
        background-color: var(--button-red);
      }
    }
  }
}

.brand-card-root-modal {
  &.modal {
    .modal-bgLayout {
      background-color: unset;
    }
    .modal-content {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      background-color: var(--main-box-color);
    }
  }
}

.brand-action-modal-root {
  display: flex;
  flex-direction: column;
  user-select: none;
  .title {
    opacity: 0.5;
    font-weight: 400;
    font-size: 12px;
    color: var(--ipanel-main-text-color);
  }
  .action-container {
    display: flex;
    gap: 5px;
    flex-direction: column;
    .action {
      cursor: pointer;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}

.brand-action-security-modal-root {
  display: flex;
  flex-direction: column;
  width: 340px;
  user-select: none;
  .title {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .key {
    display: flex;
    justify-content: center;
  }
  .error-text {
    display: flex;
    justify-content: center;
    color: #de3636;
  }
  .buttons-container {
    display: flex;
    justify-content: space-around;
    .btn {
    }
  }
}

.brand-tag-editor-root {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  gap: 16px;
  .header {
    display: flex;
    align-items: center;
    .title {
      display: flex;
      flex: 1 1 auto;
      font-weight: bold;
    }
    .action-btns {
      display: flex;
      flex: 0 0 auto;
      gap: 6px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .tag-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      gap: 6px;
      min-width: 140px;
      min-height: 250px;
      .title {
        font-weight: 500;
        margin-bottom: 4px;
      }
      .tag {
        align-self: flex-start;
        &:hover {
          transition: filter 0.2s;
          filter: brightness(0.9);
          cursor: pointer;
        }
      }
    }
  }
}

.brand-tag-editor-root-modal {
  &.modal {
    .modal-content {
      background-color: var(--main-box-color);
    }
  }
}
