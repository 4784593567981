.languages-root {
    height: 100%;
    .actions {
        padding: 2em 2em 0;
        display: flex;
        justify-content: flex-end;
        .btn {
            margin-left: 8px;
        }
    }
    .languages-container {
        padding: 1em 2em;
        height: calc(100% - 5em);
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        .language-root {
            margin: 1em 1em 1em 0;
            background: var(--main-box-color);
            border-radius: 5px;
            padding: 1.5em 1em 1.5em;
            position: relative;
            .default-language {
                position: absolute;
                border-radius: 5px;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                width: 150px;
                text-align: center;
                padding: 0.5em;
                overflow: hidden;
            }
        }
    }
}
.languages-modal {
    .modal-content {
        display: flex;
    }
    .language {
        text-transform: uppercase;
        font-size: 24px;
        background: rgba(0, 0, 0, 0.2);
        margin: 0.25em;
        padding: 0.25em;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
    }
}