.catalog-editor-root {
    .item-editor-newsfeed-root {
        .category-list {
            height: calc(100% - 3em);
        }
    }
}
.news-container {
    padding-right: 1em;
    max-width: 500px;
    height: 100%;
    overflow: auto;
    .textField-root {
        margin-bottom: 1em;
        textarea {
            height: 300px;
        }
    }
    .image-upload-root {
        .image-upload-bin {
            width: 300px;
            height: 200px;
            .dropzone {
                width: 300px;
                height: 200px;
            }
        }
    }
}