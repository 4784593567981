.slider-root{
    margin-bottom: 20px;
    .upper-row-container{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        font-weight: 600;
        .selector-container{
            display: flex;
            margin-left: 2em;
            overflow: auto;
            gap: 5px;
            .selectable{
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                padding: 0px 0.3em;
                height: 1.5em;
                cursor: pointer;
                border: 1px solid var(--module-color);
                &.selected{
                    background-color: var(--module-color);
                    color:white;
                }
            }
        }
    }
    .rc-slider{
        .rc-slider-track{
            background-color: var(--module-color);
        }
        .rc-slider-step{
            .rc-slider-dot{
                border-color: var(--module-color);
            }
        }
        .rc-slider-handle{
            background-color: var(--module-color);
            border-color: var(--module-color);
            filter: drop-shadow( 0 0 2px var(--module-color));
        }
    }
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
        border-color: var(--module-color);
        box-shadow: 0 0 5px var(--module-color);
        filter: brightness(1.2);
    }
    .rc-slider-handle{
        opacity:unset;
        background-color: #96dbfa;
    }
}