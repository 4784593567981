.inputPinCode {
  display: flex;
  //   flex-direction: column;
  position: relative;
  line-height: 0px;
  .inputField {
    //   border-style: none;
    caret-color: transparent;
    z-index: 2;
  }
  .titleText {
    position: absolute;
    top: -19px;
    font-family: Roboto;
    font-style: SemiBold;
    font-weight: bold;
    font-size: 20px;
    user-select: none;
  }
  .pinBoxes {
    background-color: var(--main-box-color);
    display: flex;
    text-align: center;
    border-style: dotted;
    border-width: 1px;
    border-color: black;
    border-radius: 20px;
    &.error {
      border-color: #b8401a;
    }
  }
  .errorText {
    font-size: 25px;
    font-family: Poppins;
    line-height: 38px;
    position: absolute;
    color: #b8401a;
    bottom: -57px;
    text-align: center;
    white-space: nowrap;
    left: -66px;
    user-select: none;
  }
}
