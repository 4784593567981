.pos-features-container {
    display: flex;
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;
    .pos-feature-box {
        border: 2px solid var(--main-box-color);
        border-radius: 5px;
        padding: 12px;
        text-align: center;
        .feature-name {
            font-size: 18px;
            font-weight: bold;
        }
        .feature-params-container {
            display: flex;
            justify-content: center;
            gap: 6px;
            .feature-param {
                display: flex;
                flex-direction: column;
                gap: 6px;
                align-items: center;
                justify-content: center;
                &.gratis {
                    cursor: pointer;
                }
            }
        }
    }
}