.user-info-box-container {
    width: 100%;
    position: relative;
    h2 {
        padding: 0 0.5em;
    }
    .user-info-box-actions {
        position: absolute;
        top: 1em;
        right: 1em;
        .btn {
            margin-left: 0.5em;
        }
    }
}
.user-info-box-userData {
    width: 600px;
    .user-info-box-userData-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn {
            margin: 0 1em 0 0;
        }
    }
    .user-info-box-userData-fields {
        display: flex;
        flex-wrap: wrap;
        width: 600px;
        .textField-root {
            margin: 1em;
            width: calc(50% - 2em);
        }
        .textField-root.gender-field {
            width: calc(20% - 1em);
            margin-right: 0.5em;
        }
        .textField-root.vip-point-field {
            width: calc(30% - 2.5em);
            margin: 1em;
        }
        .textField-root.vip-money-field {
            width: calc(50% - 2em);
        }
    }
    .user-info-box-userData-others {
        margin: 0 1em;
        width: calc(50% - 2em);
        .user-info-box-userData-others-block {
            display: flex;
            justify-content: space-between;
            margin: 0 0 1em;
        }
    }
}
.user-last-purchases-root {
    width: 800px;
    padding: 0 1em;
    .simple-table-title {
        margin: -6px auto 3px;
    }
    .simple-table {
        .simple-table-head, .simple-table-row {
            text-align: center;
            margin-bottom: 1em;
            .simple-table-cell:nth-child(1) {
                width: 20%;
            }
            .simple-table-cell:nth-child(2) {
                width: 20%;
            }
            .simple-table-cell:nth-child(3) {
                width: 40%;
            }
            .simple-table-cell:nth-child(4) {
                width: 20%;
            }
        }
    }
}
.user-last-transactions-root {
    width: 1400px;
    padding: 0 1em;
    .simple-table {
        .simple-table-head, .simple-table-row {
            text-align: center;
            margin-bottom: 1em;
            .simple-table-cell:nth-child(1) {
                width: 20%;
            }
            .simple-table-cell:nth-child(2) {
                width: 20%;
            }
            .simple-table-cell:nth-child(3) {
                width: 10%;
            }
            .simple-table-cell:nth-child(4) {
                width: 10%;
            }
            .simple-table-cell:nth-child(5) {
                width: 20%;
            }
            .simple-table-cell:nth-child(6) {
                width: 20%;
            }
        }
    }
}