.printer-settings-fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0.6em 0px;
}
.selected-option-container {
    min-height: 420px;
    max-width: 400px;
    .text {
        font-style: italic;
        font-size: 12px;
        margin-bottom: 6px;
    }
    .red-btn {
        background: var(--button-red-gradient);
        color: #fff;
    }
    .printers {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        .printer-card {
            width: 125px;
            background-color: rgba(0, 0, 0, 0.05);
            overflow: hidden;
            border-radius: 5px;
            padding: 12px;
            cursor: pointer;
            &.selected {
                background: var(--button-blue-gradient);
                color: #fff;
            }
            .printer-name {
                font-weight: bold;
            }
            .printer-type, .printer-path {
                font-size: 12px;
            }
        }
    }
}
.select-printer-option {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    .option {
        user-select: none;
        cursor: pointer;
        &.selected {
            font-weight: bold;
            border-bottom: 1px solid #000;
        }
    }
}