.modal.picker-modal {
    .modal-content {
        height: 400px;
        width: 300px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .modal-body {
            flex: 1 1 auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .items-container {
                overflow: auto;
                padding: 12px;
                flex: 1 1 auto;
                .item-container {
                    cursor: pointer;
                    padding: 6px 12px;
                    &:hover {
                        background: rgba(0, 0, 0, 0.2)
                    }
                }
            }
        }
    }
}