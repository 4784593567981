.no-brand-selected-container {
    width: 100%;
    height: 100%;
    padding: 2em;
    overflow: auto;
    .message {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 1em;
    }
    .brands-container {
        width: 100%;
        overflow: hidden;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn {
        margin: 0.5em;
    }
}