.main-mobile-container {
    .main-mobile-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: var(--main-background-color);
        color: var(--ipanel-main-text-color);
        display: flex;
        gap: 6px;
        flex-direction: column;
        position: relative;
        font-family: 'Barlow', sans-serif;    
        .okeoke-data-table {
            .data-cell {
                max-width: 1000px;
            }
        }
    }
    .version-container {
        bottom: 6px;
        left: 6px;
        font-size: 10px;
    }
    .change-orientation {
        width: 100%;
        height: 100%;
        background-color: #17151F;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-container {
            display: flex;
            flex-direction: column;
            gap: 6px;
            animation: rotate 2s infinite;
            .svg-root {
                &.rotate {
                    height: 60px;
                    width: 60px;
                }
                &.phone {
                    height: 80px;
                    width: 60px;
                }
            }
            svg {
                .svgFill {
                    fill: #ECEFF1;
                }
                .svgStroke {
                    stroke: #ECEFF1;
                }
            }
        }
    }
    .under-construction {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        align-items: center;
        .title {
            font-weight: bold;
            font-size: 22px;
        }
        .svg-root {
            height: 80px;
            width: 80px;
        }
    }
}