.okeoke-coupon-info-box-main-container {
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    .header-text {
      font-size: 1.5em;
      font-weight: bold;
    }
    .header-buttons {
      display: flex;
      .btn {
        margin-left: 0.5em;
      }
    }
  }
  .containers {
    display: flex;
    flex-wrap: wrap;
    width: 100%;   
    .left-container {
      width: 500px;
      .upper-part {
        max-width: 450px;
        .textField-root {
          margin-bottom: 1em;
        }
      }
      .middle-part {
        width: 450px;
        min-height: 200px;
        display: grid;
        gap: 1em;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 3.5em;
        margin-bottom: 1em;
        .textField-root {
          
        }
      }
      .bottom-part {
        width: 450px;
        .bottom-part-input-fields {
          display: grid;
          gap: 1em;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 3.5em;
        }
      }
    }
    .right-container {
      width: 500px;
      .function-buttons {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        .coupon-info-box-button {
          width: 200px;
        }
      }
      .text-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0.5em 0;
        .text {
          width: 50%;
          font-style: italic;
          font-size: 0.8rem;
        }
      }
      .summary-header {
        width: 100%;
        font-weight: bold;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #d3d3d3;
      }
      .summary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 1.25rem 0;
      }
      .condition-of-basket-container {
        margin-bottom: 1rem;
        max-height: 220px;
        overflow: auto;
        .condition-header {
          width: 100%;
          font-weight: bold;
        }
        .condition-row {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
      }
      .affected-items-container {
        max-height: 220px;
        overflow: auto;
        .affected-items-header {
          width: 100%;
          font-weight: bold;
        }
        .affected-item-row {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
.modal.okeoke-coupon-condition-modal {
  .modal-content {
    min-width: 600px;
  }
  .okeoke-coupon-condition {
    max-width: 1000px;
    max-height: 600px;
    overflow: hidden;
    .coupon-condition-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;      
      .title {
        font-size: 2em;
        font-weight: bold;
      }
      .actions {
        display: flex;
        align-items: center;
        .btn {
          margin-left: 0.5em;
        }
      }
    }
    .coupon-condition-main-content {
      margin-top: 1rem;
      display: flex;
      height: 500px;
      overflow: hidden;
      .main-content-side {
        width: calc(50% - 1px);
        height: 100%;
        .tooltip {
          display: flex;
          align-items: center;
          height: 2em;
          margin-bottom: 0.5em;
          .svg-container {
            margin-right: 0.5rem;
          }
        }
        .side-content {
          height: calc(100% - 2.5em);
          .side-content-header {
            display: flex;
            margin-bottom: 0.5em;
            .text {
              width: 50%;
              &.right {
                text-align: center;
              }
            }
          }
          .side-content-content {
            overflow: auto;
            height: calc(100% - 2.5em);
          }
        }
      }
      .main-content-side.full-content {
        width: 100%;
        .side-content {
          overflow: auto;
        }
      }
      .main-content-left-side {
        padding-right: 0.5em;
        border-right: 1px solid var(--lightgrey2);
      }
      .main-content-right-side {
        padding-left: 0.5em;
        border-left: 1px solid var(--lightgrey2);
      }
    }
  }
}
.okeoke-tree {
  .tree-trunk {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    .trunk-checkbox {
      width: 25px;
      height: 25px;
      border-radius: 5px;
      background-color: #e4e3e3;
      cursor: pointer;
      &.checked {
        background: linear-gradient(99.58deg, #ffab48 0.14%, #ff5c5c 98.71%);
      }
    }
    .svg-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.5em 0 0.5em;
    }
    .svg-container.turn-down {
      transform: rotate(180deg);
      transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .svg-container.turn-up {
      transform: rotate(0deg);
      transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .trunk-text {
      margin-left: 0.5em;
      cursor: pointer;
      user-select: none;
    }
  }
  .tree-branch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
    height: 35px;
    .tree-branch-left-side {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      width: 50%;
      .branch-checkbox {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        background-color: #e4e3e3;
        cursor: pointer;
        &.checked {
          background: linear-gradient(99.58deg, #ffab48 0.14%, #ff5c5c 98.71%);
        }
      }
      .branch-text {
        display: flex;
        align-items: center;
        margin-left: 0.5em;
        user-select: none;
        height: 1.3em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .tree-branch-right-side {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.tree-element-detail-items {
  display: flex;
  .textField-root {
    width: 30px;
    .textField-base {
      .textField-input {
        padding: 0.5em;
        text-align: right;
      }
    }
  }
  .input-field-select-main-container.textField-root {
    margin-left: 0.5em;
    .textField-base {
      padding: 0.5em;
      text-align: center;
    }
  }
}

.tree-element-detail-inputfield {
  width: 30px;
  &.textField-root {
    .textField-base {
      .textField-input {
        padding: 0.5em;
        text-align: right;
      }
    }
  }
}

.grid-template-columns-1fr-1fr {
  grid-template-columns: 1fr 1fr;
}
