.main-mobile-content {
    .content-head-container {
        flex-shrink: 0;
        background-color: #17151f;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        position: relative;
        overflow: hidden;
        padding: 6px 0px 12px;
        align-items: center;
        min-height: 135px;
        .swiper {
            padding-left: 12px;
            overflow: visible;
            .swiper-wrapper {
                align-items: center;
                min-height: 56px;
            }
        }
        .info-bar {
            display: flex;
            padding: 0 12px 0 6px;
            margin-bottom: 12px;
            align-items: center;
            z-index: 5;
            position: relative;
            .user-menu {
                margin: 2px 6px auto 0;
                svg {
                    .svgFill {
                        fill: #ECEFF1;
                    }
                }
            }
            .left-side {
                flex: 1 1 auto;
                .selected-menu {
                    color: var(--module-color);
                    font-size: 21px;
                }
                .selected-brand{
                    color: #ECEFF1;
                }
            }
            .right-side {
                flex-shrink: 0;
                display: flex;
                gap: 6px;
                .header-action-container {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #17151F;
                    border-radius: 10px;
                    .action-btn {
                        width: 24px;
                        height: 24px;
                        justify-content: center;
                        align-items: center;
                        .svg-root {
                            width: 100%;
                            height: 100%;
                            .svgFill {
                                fill: #ECEFF1;
                            }
                            .svgStroke {
                                stroke: #ECEFF1;
                            }
                        }
                    }
                }
            }
        }
        .header-menu-element {
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid transparent;
            color: #ECEFF1;
            &.selected {
                transition: all 0.3s;
                //background-color: var(--module-color);
                border: 1px solid var(--module-color);
                box-shadow: 0px 0px 10px 0px var(--module-color);
            }
        }
        .bg-circle {
            width: 90px;
            height: 90px;
            background-color: var(--module-color);
            border-radius: 100%;
            position: absolute;
            opacity: 0.5;
            filter: blur(60px);
            z-index: 0;
            &.bg-circle-left {
                top: 70px;
                left: -30px;
            }
            &.bg-circle-right {
                top: 12px;
                right: 12px;
            }
        }
    }
}