.filters-root {
    width: 400px;
    padding: 0 2em;
    height: 100%;
    overflow: auto;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    .accordion-root.filters-accordion {
        margin-bottom: 1em;
        .accordion-header {
            border: none;
            background-color: var(--lightblue);
            border-radius: 5px;
            padding-left: 2em;
            .accordion-expandIcon {
                font-size: 10px;
                right: 1em;
            }
        }
        .accordion-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 1em;
            .textField-root {
                margin: 0.75em 2em;
            }
        }
    }
    .filter-field {
        margin-bottom: 1em;
        .textField-base {
            background: var(--lightblue);
        }
    }
}
.filter-container {
    .actions {
        padding: 0 2em;
        .btn {
            width: 100%;
            margin: 1em auto 0;
            height: 3em;
        }

    }
}