/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
@media (min-width:600px) {
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);;
        border-radius: 5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.3);
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.5);
    }
}