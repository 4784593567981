.main-mobile-container {
    .modal {
        backdrop-filter: blur(2px) brightness(0.8);
        .modal-content {
            width: 95%;
        }
        .modal-header {
            padding: 0;
            flex: none;
        }
        .modal-actions {
            display: flex;
            justify-content: flex-end;
            gap: 12px;
        }
    }
    .modal.user-modal.mobile-user-modal {
        .modal-content {
            height: calc(100% - 150px);
            top: 60px;
            right: unset;
        }
    }
}