.brand-params-tabs-container {
  display: flex;
  margin-bottom: 0.5em;
  height: 3em;
  width: 100%;
  overflow: auto;
  font-size: 18px;
  .left-side{
    display: flex;
    border-right: 4px solid var(--ipanel-border);
  }
  .right-side{
    display: flex;
  }
  .tab {
    cursor: pointer;
    margin-right: 8px;
    padding: 0.5em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-width: fit-content;
    display: flex;
    align-items: center;
    opacity: 0.8;
    &.selected {      
      opacity: 1;
      font-weight: bold;
    }
    &:hover{
      opacity: 1;
    }
  }
}
.brand-settings-block {
  display: flex;
  align-items: center;
  .reset-container {
    width: 30px;
  }
}
.selected-tab {
  .create-brand-wizard-root {
    padding-right: 1em;
    .stages-container {
      .stage-container {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    .actions-container {
      justify-content: flex-start;
      padding-left: 2em;
    }
  }
  .brand-params-root {
    width: 100%;
    height: 100%;
  }
  .brand-params-container {
    width: 100%;
    height: calc(100% - 3.5em);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    align-content: flex-start;
    overflow: auto;
    &.navigation {
      .info-box {
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: auto;
        .box-title {
          display: none;
        }
        .sub-info-box {
          padding: 0;
          &.more-menu {
            max-width: 300px;
          }
          .box-title {
            display: block;
          }
          .nav-element-editor-root {
            flex-wrap: nowrap;
            align-items: center;
            margin: 0;
            .icon-selector-root {
              &.disabled {
                opacity: 0.2;
              }
            }
            .textField-root {
              margin: 1em;
            }
            .delete-icon {
              top: 0;
              right: 0;
              background: var(--main-background-color);
              border-radius: 100%;
              padding: 3px;
              svg {
                .svgText {
                  fill: var(--ipanel-main-text-color);
                }
              }
            }
          }
        }
      }
    }
  }
  .info-box {
    .image-upload-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .textField-root {
        margin: 6px;
      }
    }
    .block {      
      display: flex;
      align-items: center;
      .reset-container {
        height: 24px;
        width: 24px;
        svg {
          cursor: pointer;
        }
      }
    }
  }
  .sub-info-box {
    .box-title {
      margin-bottom: 0;
    }
    padding-left: 8px;
    margin-bottom: 2em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .color-picker-root {
    display: flex;
    align-items: center;
    position: relative;
    .color-picker-label {
      padding-right: 8px;
      width: calc(100% - 90px);
      overflow: hidden;
    }
  }
  .nav-element-editor-root {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    .delete-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      height: 20px;
      width: 20px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
        .svgText {
          fill: #000;
        }
      }
    }
  }
}
.color-picker-container {
  display: flex;
  align-items: center;
  width: 90px;
  input {
    margin-right: 1em;
  }
  .reset-container {
    height: 24px;
    width: 24px;
    svg {
      cursor: pointer;
      height: 100%;
      width: 100%;
    }
  }
}
