.nav-container {
    width: 100%;
    height: calc(100% - 208px);
    padding: 4em 8px 8px;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
    justify-content: flex-end;
    position: relative;
    transition: all 1s;
    .nav-toggler {
      transition: all 1s;
      position: absolute;
      top: 25px;
      right: -20px;
      padding: 8px;
      background: var(--panel-bg-color);
      border-radius: 100%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      cursor: pointer;
      .svg-root {
        svg {
            .svgFill {
                fill: #F3F3F3;
            }
        }
      }
    }
    .nav-element {
      display: flex;
      align-items: center;
      transition: margin 1s;
      cursor: pointer;
      height: 2em;
      width: 100%;
      transition: all 0.5s;
      .svg-container {
        height: 28px;
        width: 28px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          .svgFill {
            fill: #AFB0B4;
          }
          .svgStroke {
            stroke: #AFB0B4;
          }
        }
      }
      &:hover {
        background-color: #282B32;
        .svg-root {
          svg {
            .svgFill {
              fill: #F3F3F3;
            }
            .svgStroke {
              stroke: #F3F3F3;
            }
          }
        }
        .nav-element-title {
          color: #F3F3F3;          
        }
      }
      .nav-element-title {    
        width: calc(100% - 2em - 3px);   
        color: #D9DAE0;
        font-weight: 500;
        transition: opacity 1s;
        white-space: nowrap;
        overflow: hidden;
      }
    }    
    .nav-element.selected {      
      background-color: #1f2023;
      border-radius: 5px;
      .svg-root {
        svg {
          .svgFill {
            fill: #F3F3F3;
          }
          .svgStroke {
            stroke: #F3F3F3;
          }
        }
      }
      .nav-element-title {
        color: #F3F3F3;
      }
      &.logout {
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
    .nav-element-group {
      height: 100%;
      width: 100%;
      overflow: auto;
      .nav-element-outer {
        position: relative;
        .svg-root {
          svg {
            .svgFill {
              fill: #AFB0B4
            }
          }
        }
        .sub-element-toggler {
          position: absolute;
          top: 3px;
          right: 4px;
          cursor: pointer;
          &.closed {
            transform: rotate(180deg);
            transition: transform 0.3s;
          }
          &.open {
            transform: rotate(0deg);
            transition: transform 0.3s;
          }
        }
      }
      .nav-element {
        &.no-sub {
          margin-bottom: 8px;
        }
        &.with-sub {
          margin-bottom: 0.25em;
        }
      }
    }  
    .sub-elements {
      padding-left: 4px;
      transition: height 0.3s;
      overflow: hidden;
      padding-bottom: 0.5em;
      &.open {
        transition: max-height 0.3s;
        max-height: 1000px;
      }
      &.closed {
        transition: max-height 0.3s;
        max-height: 0;
        padding: 0;
      }
      .nav-element {
        .svg-root {
          margin-bottom: 2.5em;
          height: 2.5em;
        }
        &.selected {
          background-color: #1f2023;
        }
      }
    }
}