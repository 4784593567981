.roles-modal {
  .fields {
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .input-switch-button-container-root {
      margin: 6px;
      width: 180px;
    }
  }
}
