.modal.help-modal {
    h1 {
        margin-top: 0;
    }
    .modal-content {
        max-width: 600px;
    }
}
.linked-topic-accordion {
    margin-top: 1em;
    .accordion-header {
        background-color: var(--main-background-color);
        border-radius: 5px;
        .svg-root {
            svg {
                .svgFill {
                    fill: var(--module-color);
                }
            }
        }
    }
    &.accordion-open {
        border: 1px solid var(--ipanel-border);
        border-radius: 5px;
    }
}
.help-icon-btn-root {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    .svg-root {
        svg {
            .svgFill {
                fill: var(--button-blue);
            }
        }
    }
}