.order-status-root {
    .order-status-svg-container {
        height: 351px;
        width: 351px;
        margin: auto;
        .order-status-msg-box {
            height: 351px;
            width: 351px;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .order-status-text {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }
    .order-status-orderNumber {
        text-align: center;
        .order-number {
            font-weight: bold;
            font-size: 3em;
        }
    }
    .order-status-est-time, .order-status-req-time {
        text-align: center;
    }
}
.orderview-order-details {
    background: #000;
    padding: 1em;
    border-radius: 4px;
    margin-top: 1em;
    .btn {
        margin-top: 1em;
    }
}