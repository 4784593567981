.device-state {
  position: absolute;
  top: 0px;
  left: 0px;
  background: var(--main-box-color);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.row {
    padding: 0px;
    flex-direction: row;
  }
  &.red {
    .svg-root {
      svg {
        .svgFill {
          fill: var(--button-red);
        }
      }
    }
  }
  &.green {
    .svg-root {
      svg {
        .svgFill {
          fill: var(--button-green);
        }
      }
    }
  }
  &.yellow {
    .svg-root {
      svg {
        .svgFill {
          fill: var(--button-orange);
        }
      }
    }
  }
  .svg-root {
    svg {
      .svgFill {
        fill: var(--ipanel-main-text-color);
      }
    }
  }
}
.view-root {
  &.devices {
    .view-actions {
      min-height: 4.5em;
    }
    .view-content {
      overflow: auto;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
    }
  }
}
.device-error-circle {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  border-radius: 50%;
  font-size: 1em;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  &.red {
    z-index: 1;
    background-color: var(--button-red);
  }
  &.yellow {
    background-color: var(--button-orange);
  }
}
.device-outer-container {
  position: relative;
  width: 240px;
  height: 250px;
  margin: 1em 1em 0 0;
  border-radius: 5px;
  background-color: var(--main-box-color);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  &.disabled {
    opacity: 0.4;
  }
  .device-icon {
    margin: 1em 0 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-root {
      width: 100px;
      height: 100px;
    }
  }
  .device-header-label {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    height: 2.5em;
    cursor: default;
  }
  .device-data {
    height: calc(100% - 200px);
    .data-block {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 0 1em;
      margin-bottom: 0.5em;
    }
  }
  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    &:hover {
      .btn {
        .svg-container {
          .svg-root {
            svg {
              .svgStroke {
                stroke: var(--ipanel-main-text-color);
              }
              .svgFill {
                fill: var(--ipanel-main-text-color);
              }
            }
          }
        }
      }
    }
  }
}
.printer-types {
  display: flex;
  gap: 12px;
  .printer-data-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
    width: 33%;
    padding: 6px;
    border-radius: 5px;
    .title {
      font-weight: bold;
    }
    &.selected {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .btn {
      text-align: center;
    }
    .select-printer {
      text-decoration: underline;
      cursor: pointer;
      font-size: 12px;
    }
  }
}
.selected-device {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: column;
  overflow: auto;
}
.device-data-box {
  background-color: var(--main-box-color);
  width: 400px;
  border-radius: 5px;
  padding: 1em;
  margin: 1em;
  position: relative;
  &.device-state-box {
    .errors {
      display: flex;
      flex-direction: column;
      max-height: 100px;
      padding-right: 1em;
      overflow: auto;
      gap: 0.4em;
      .error {
        display: flex;
        .text {
          display: flex;
          justify-content: flex-start;
          flex: 1 1 auto;
        }
      }
    }
  }
  &.payment-agents-data {
    background-color: rgba(0, 0, 0, 0.05);
    margin: 0 12px;
    .btn {
      margin: 0 6px 6px 0;
    }
  }
}
.activation-type-container {
  display: flex;
  align-items: center;
  margin-top: 1em;
  .textField-root {
    margin-right: 1em;
  }
  .pin-container {
    height: 2em;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--button-blue);
    border-radius: 5px;
  }
}
.select-terminal-root {
  .select-terminal-label {
    margin-bottom: 0.5em;
  }
  .select-terminal-container {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    .btn {
      margin-left: 8px;
    }
  }
}

.device-list-mode-root {
  position: relative;
  background-color: var(--main-box-color);
  width: 400px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  .content {
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      .device-state {
        position: relative;
        height: unset;
        width: unset;
        height: 12px;
        background-color: unset;
      }
    }
    .bottom {
      display: flex;
      .data-value {
      }
    }
  }
  .device-icon {
    width: 50px;
    height: 100%;
    .svg-root {
      width: 100%;
      height: 100%;
    }
  }
}
