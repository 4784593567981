.checkbox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: 1px solid var(--ipanel-border);
  border-radius: 6px;
  &:hover{
    cursor: pointer;
  }
  &.checked {
    background-color: var(--module-color);
    border-color: var(--module-color);
  }
  .svg-root {
    svg {
      .svgStroke {
        stroke: white;
      }
      .svgFill {
        fill: white;
      }
    }
  }
}
