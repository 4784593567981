@media screen and (orientation: portrait) {
  .external-brand-creator-root {
    height: 100%;
    .wizard-conainer {
      width: 100%;
      height: 100%;
      .create-brand-wizard-root {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 6px;
        .progress-bar-root {
          font-size: 12px;
          .stage-container {
            align-items: center;
            margin: 2px;
            padding: 4px;
            .stage-name {
              text-align: center;
              padding: 3px 5px;
            }
            .stage-circle {
              width: 40px;
              height: 40px;
            }
          }
          .horizontal-line {
            bottom: 17px;
          }
        }
        .stages-container {
          flex: 1 1 auto;
          overflow: auto;
          .textField-root {
            width: 100%;
          }
        }
        .actions-container {
          flex: 0 0 54px;
        }
      }
    }
  }
}
