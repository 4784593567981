.app-editor-root {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .main-nav {
    position: relative;
    background-color: var(--main-box-color);
    border-right: 1px solid var(--panel-bg-color);
    padding: 32px 0;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-shrink: 0;
    overflow: hidden;
    &.closed {
      width: 0;
      transition: width 0.3s ease-in-out;
    }
    &.open {
      width: 68px;
      transition: width 0.8s ease-in-out;
    }
    .nav-element {
      margin: 0 auto;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      user-select: none;
      overflow: hidden;
      .svg-root {
        width: 28px;
        height: 28px;
      }
      &:hover {
        border: 1px solid var(--module-color);
      }
      &.selected {
        background-color: var(--module-color);
        transition: all 0.3s ease-in-out;
        .svg-root {
          .svgFill {
            fill: #fff;
          }
          .svgStroke {
            stroke: #fff;
          }
        }
      }
    }
  }
  .selected-content {
    flex-shrink: 0;
    background-color: var(--main-box-color);
    filter: drop-shadow(8px 0px 11px rgba(0, 0, 0, 0.25));
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &.closed {
      width: 0;
      transition: width 0.3s ease-in-out;
    }
    &.open {
      width: 350px;
      transition: width 0.8s ease-in-out;
    }
    &.extended-cover {
      flex: 1 1 auto;
    }
    &.no-background {
      background-color: unset;
    }
    .selected-content-header {
      display: flex;
      .selected-content-title {
        font-size: 22px;
      }
    }
    .info-box {
      overflow: auto;
      border-radius: 8px;
      width: 300px;
      margin: 1em;
      user-select: none;
      .block {
        .input-switch-button-container-root {
          overflow: hidden;
        }
        .textField-root{
          width: 100%;
          margin: 0.5em 0px;
        }
      }
      .image-upload-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        .image-upload-box {
          margin-bottom: 15px;
          width: 90%;
          max-width: 90%;
          .image-upload-root {
            .label {
              display: flex;
              justify-content: center;
              text-align: center;
              padding: 0px;
            }
          }
          .input-field-select-main-container.textField-root {
            .textField-base {
              cursor: pointer;
              display: block;
              padding: 27px 12px 10px;
              width: 240px;
              margin: 3px;
            }
            &.fullWidth {
              .textField-base {
                width: 100%;
              }
            }
          }
        }
      }
      .box-title {
        font-size: 22px;
        font-weight: 400;
      }
    }
    .app-editor-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .app-selector {
    width: 150px;
    height: 32px;
    margin: auto;
    background-color: var(--main-box-color);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    cursor: pointer;
    .app-btn {
      &.selected {
        .svg-root {
          svg {
            .svgStroke {
              stroke: var(--button-blue);
            }
            .svgFill {
              fill: var(--button-blue);
            }
          }
        }
      }
    }
  }
  .sub-nav {
    flex-shrink: 0;
    background-color: var(--main-box-color);
    box-shadow: -8px 0px 11px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    &.closed {
      width: 0;
      transition: width 0.3s ease-in-out;
    }
    &.open {
      width: 418px;
      transition: width 0.8s ease-in-out;
    }
    h2 {
      padding: 0 12px;
    }
    .nav-sub-element {
      margin: 0 auto;
      width: 90%;
      padding: 8px 14px;
      border-radius: 5px;
      font-size: 18px;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
      border: 1px solid transparent;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      user-select: none;
      &:hover {
        filter: brightness(1.2);
        box-shadow: unset;
      }
      &.selected {
        transition: all 0.3s ease-in-out;
        background-color: var(--module-color);
        border-color: var(--module-color);
        color: #fff;
        box-shadow: unset;
      }
    }
  }
  .app-live-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    h3 {
      text-align: center;
      opacity: 0.6;
    }
    p {
      text-align: center;
    }
    .main-window-root {
      display: flex;
      object-fit: contain;
      flex: 1 1 auto;
      justify-content: center;      
      padding: 0.5em;
    }
  }
  .color-groups {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .color-group {
    padding: 6px 12px;
    .title {
      text-align: center;
      margin-bottom: 0.5em;
      font-size: 17px;
    }
    .color-boxes {
      display: flex;
      flex-wrap: wrap;
    }
    .line {
      height: 1px;
      width: 80%;
      margin: 1em auto;
      background-color: #d5d5d5;
    }
  }
  .color-picker-root {
    width: 75px;
    .color-picker-inner-container {
      width: 50px;
      height: 50px;
      margin: 10px 15px;
      border-radius: 3px;
      background-color: #e7e7e7;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
      .color-picker-inner-background {
        width: 65%;
        height: 65%;
        cursor: pointer;
        border: 2px solid #c7c7c7;
        display: flex;
        align-items: center;
        justify-content: center;
        .svg-root {
          width: 80%;
          height: 80%;
          svg {
            .svgFill {
              fill: #c7c7c7;
            }
          }
        }
      }
    }
    .color-picker-label {
      text-align: center;
      overflow: hidden;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
.sketch-modal {
  .modal-content {
    align-items: center;
  }
  .reset-container {
    margin-top: 1em;
    .reset-btn {
      background-color: var(--button-red);
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: #fff;
      padding: 6px 10px;
      cursor: pointer;
      .svg-root {
        svg {
          .svgFill {
            fill: #fff;
          }
        }
      }
    }
  }
}

.dark-mode {
  .app-editor-root .color-picker-root .color-picker-inner-container {
    background-color: #383940;
  }
}
