.dashboard-root {
  padding: 1em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  font-family: "Barlow", sans-serif;
  .date-selector {
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    .btn {
      margin-left: 12px;
    }
    .textField-root {
      .textField-base {
        background-color: var(--main-box-color);
      }
    }
    .input-switch-root {
      width: 200px;
      display: inline-block;
      margin-left: 1em;
    }
  }
  .dashboard-box-data-table {
    box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: column;
    width: 350px;
    flex: 0 1 auto;
    overflow: hidden;
    border-radius: 6px;
    background: var(--main-box-color);
    .okeoke-data-table {
      padding: unset;
      .data-cell{
        white-space: nowrap;
        &.column-svg{
          min-width: 15%;
          width: 15%;
          max-width: 15%;
          height: 34px;
        }
      }
      .data-table {
        .data-header{
          .data-cell{
            height: unset;
            padding: unset;
          }
        }
        tbody {
          tr {
            td {
              border: unset;
            }
          }
        }
      }
    }
    svg {
      .svgStroke {
        height: 1.2em;
        width: 1.2em;
        stroke: white;
      }
    }
  }
  .column-name {
    padding: unset;    
    padding-bottom: 5px;
    width: 30px;
    font-size: 16px;
    font-weight: 500;
  }
  .column-value {
    font-size: 14px;
    font-weight: 500;
    width: 40%;
  }
}

.box-group {
  display: flex;
  flex-wrap: wrap;
  &.flex-dir-row {
    flex-direction: row;
  }
  &.flex-dir-col {
    flex-direction: column;
  }
}

.dashboard-box {
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  background: var(--main-box-color);
  border-radius: 6px;
  width: 550px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 16px 0;
  position: relative;
  .okeoke-loading-screen {
    border-radius: 6px;
  }
  .title {
    width: 100%;
    padding: 12px 0 0 12px;
  }
  &.large{
    width: 842px;
    height: 430px;
  }
  &.small {
    width: 270px;
    height: 100px;
    .label {
      opacity: 0.6;
      width: 100%;
      font-size: 18px;
    }
    .value-container {
      width: 100%;
      display: flex;
      font-size: 28px;
      .svg-root {
        margin-right: 0.2em;
        width: 1.2em;
        height: 1.2em;
        svg {
          .svgStroke {
            stroke: white;
          }
        }
      }
      .value {
        font-weight: bold;
        width: 100%;
      }
    }
  }
  &.height-fluid {
    width: 640px;
    height: max-content;
  }
  .chart-head {
    width: 100%;
    padding: 12px 0 12px 12px;
  }
  .chart-container {
    width: 100%;
    height: 100%;
    padding: 12px;
    .text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .okeoke-data-table {
    box-shadow: none;
  }
}
