.help-container {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    .textField-root {
        width: 100%;
        margin-bottom: 1em;
    }
}
.item-editor-help-root {
    display: flex;
    flex-direction: column;
    .header {
        flex: 0 0 28px;
    }
    .category-list {
        flex: 1 1 auto;
    }
}