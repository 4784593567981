.templates-root {
    .view-content {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
    }
}
.template-group {
    width: 100%;
    .templates {
        display: flex;
        flex-wrap: wrap;
    }
}
.template-card-root {
    width: 500px;
    height: 250px;
    margin: 1em 2em 1em 0;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    position: relative;
    .template-card-img {
        width: 200px;
        height: 250px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .template-card-content {
        background-color: var(--main-box-color);
        padding: 0.5em;
        height: 100%;
        width: calc(100% - 200px);
        overflow: hidden;
        .template-card-data {
            margin-bottom: 0.25em;
            .template-card-data-label {
                opacity: 0.7;
            }
            .template-card-data-value {
                overflow: hidden;
            }
        }
    }
    .template-type {
        position: absolute;
        right: -50px;
        bottom: 30px;
        transform: rotate(-45deg);
        width: 200px;
        text-align: center;
        padding: 0.5em;
    }
    .template-type.default {
        background: var(--button-blue-gradient);
        color: #fff;
    }
    .template-type.custom {
        background: var(--button-orange-gradient);
        color: #fff;
    }
}