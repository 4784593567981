.orderHeader-root {
    display: flex;
    justify-content: center;
    align-items: center;
    .order-location {
        text-transform: uppercase;
    }
    .order-totalPrice {
        font-weight: bold;
    }
    .order-msg {
        font-style: italic;
    }
    .order-orderState-label {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1em;
        font-style: italic;
    }
    .order-orderState {
        text-transform: uppercase;
        background-color: #fff;
        color: #000;
        padding: 3px 12px;
        border-radius: 30px;
        text-align: center;
        line-height: 1;
        font-style: italic;
        font-weight: bold;
    }
}