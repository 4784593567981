.submitted-orderItem-root {
    .submitted-orderItem-header {
        display: flex;
        justify-content: center;
        .submitted-orderItem-name {
            display: flex;
        }
        .submitted-orderItem-size {
            margin-right: 4px;
        }
    }
    .submitted-orderItem-details {
        padding: 0 1em;
        opacity: 0.6;
        .submitted-orderItem-modifierGroups {
            .modifierGroup-root {
                .modifierGroup-name {
                    display: none;
                }
                .modifier-root {
                    display: flex;
                    .modifier-count {
                        margin-right: 4px;
                        width: 10px;
                    }
                    .modifier-name {
                        width: calc(100% - 80px);
                    }
                    .modifier-price {
                        width: 70px;
                        text-align: right;
                    }
                }
            }
        }
    }
}