.printer-error-display-root{
    width: 300px;
    .error-row {
        display: flex;
        justify-content: space-between;
        margin: 0.5em 0;
        .name{

        }
        .value {
            height: 16px;
            width: 16px;
            background:rgba(0, 0, 0, 0.3);
            border-radius: 100%;
            &.blue {
                background: #24E0FF;
                box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3F8CFF 0 2px 14px;
            }
            &.green {
                background-color: #ABFF00;
                box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
            }
            &.red {
                background: #F00;
                box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
            }
        }
    }
}