.svg-uploader-root {
  position: relative;
  height: 130px;
  width: 130px;
  border: solid 1px var(--module-color);
  border-radius: 12px;
  background-color: var(--main-box-color);
  overflow: hidden;
  padding: 0.5em;
  &:hover {
    cursor: pointer;
  }
  .delete-icon{
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .dropzone {
    .name {
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: var(--module-color);
      color: var(--panel-text-color);
      padding: 4px;
      border-bottom-right-radius: 12px;
    }
    .svg-container {
      svg {
        max-height: 100%;
      }
    }
    .svgless {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      .svg-root {
        svg {
          .svgStroke {
            stroke: var(--module-color);
          }
        }
      }
      .btn-brand-color {
        background-color: var(--module-color);
      }
    }
  }
}
