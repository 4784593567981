.registrations-root {
  .actions-left {
    gap: 12px;
  }
}
.init-brand-modal {
  .init-brand-modal-content {
    .errors {
      display: flex;
      flex-wrap: wrap;
      color: var(--button-red);
      .error {
        margin: 0 6px;
      }
    }
  }
  .data-block {
    margin: 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--grey);
    .textField-root {
      margin: 6px;
      &.long {
        min-width: 400px;
      }
    }
    .label {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .error {
      color: var(--button-red);
    }
  }
  .sub-data-block {
    padding: 12px;
    .label {
      font-size: 18px;
    }
  }
}
