.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    color: var(--ipanel-main-text-color);
    &.overflow-visible{
        .modal-content{
            overflow: visible;
        }
    }
    .close-icon {
        position: absolute;
        top: 0.25em;
        right: 0.25em;
        height: 28px;
        width: 28px;
        z-index: 998;
        cursor: pointer;
    }
    .modal-title {
        text-align: center;
        margin-bottom: 1em;
    }
    .modal-bgLayout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
    .modal-content {
        z-index: 20;
        position: relative;
        max-height: 90vh;
        max-width: 90vw;
        overflow: auto;
        background: var(--main-background-color);
        border-radius: 5px;
        padding: 1em;
        display: flex;
        flex-direction: column;
        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1em;
            padding-bottom: 0.5em;
            border-bottom: 1px solid var(--grey);
            flex: 0 0 42px;
            .modal-title {
                margin: 0;
                font-size: 2em;
                font-weight: bold;
                padding-right: 30px;
            }
            .modal-actions {
                display: flex;
                align-items: center;
                .btn {
                    margin-left: 0.5em;
                }
            }
        }
        .modal-body {
            flex: 1 1 auto;
            overflow: auto;
            padding-right: 8px;
        }
        .actions {
            text-align: right;
        }
        .fields {
            display: flex;
            flex-direction: column;
            .textField-root, .input-switch-root {
                margin: 0.5em auto;
            }
        }
    }
}
.modal-appear-effect {
    animation: 0.3s appear cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}