.option-tree-element {
  display: flex;
  .display-box {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background-color: #e4e3e3;
    align-self: center;
    &.selected {
      background: linear-gradient(99.58deg, #ffab48 0.14%, #ff5c5c 98.71%);
    }
  }
  .svg {
    align-self: center;
    margin-left: 13px;
    svg {
      fill: #a7a7a7;
    }
    &.rotate {
        transform: rotate(90deg);
    }
  }
  .text {
    margin-left: 13px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 37px;
  }
}
