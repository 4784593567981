.ntak-order-editor {
    background-color: var(--main-background-color);
    display: flex;
    gap: 18px;
    overflow: hidden;
    min-width: 300px;
    min-height: 300px;
    flex-direction: column;
    .header {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        .title {
            font-size: 18px;
            font-weight: bold;
        }
        .actions {
            display: flex;
            gap: 12px;
        }
    }
    .versions {
        display: flex;
        gap: 12px;
        .version-btn {
            border: 2px solid var(--button-blue);
            color: var(--button-blue);
            padding: 6px 16px;
            border-radius: 5px;
            transition: all 0.3s;
            cursor: pointer;
            &.selected {
                background-color: var(--module-color);
                color: #fff;
            }
        }
    }
    .row-title {
        font-weight: bold;
        font-size: 18px;
        margin-top: 12px;
        position: relative;
        &:first-of-type {
            margin-top: 0;
        }
    }
    .col-title {
        font-size: 16px;
        padding-right: 25px;
    }
    .row {
        display: flex;
        gap: 6px;
        overflow: hidden;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.05);
        position: relative;
        .add {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            cursor: pointer;
            height: 25px;
            .svg-root {
                background-color: var(--button-blue);
                padding: 3px;
                border-bottom-left-radius: 5px;
                svg {
                    .svgStroke {
                        stroke: #fff;
                    }
                }
            }
        }
        .row-inner {
            display: flex;
            gap: 6px;
            overflow: auto;
            width: 100%;
            position: relative;
        }
    }
    .col {
        border-radius: 5px;
        padding: 6px;
        background-color: rgba(0, 0, 0, 0.05);
        display: flex;
        gap: 6px;
        flex-direction: column;
        margin-bottom: 6px;
        position: relative;
        .edit {
            position: absolute;
            top: 6px;
            right: 6px;
            cursor: pointer;
        }
    }
    .data-block {
        background-color: var(--main-box-color);
        border-radius: 5px;
        padding: 6px 27px 6px 6px;
        min-height: 43px;
        min-width: 200px;
        position: relative;
        .label {
            font-size: 12px;
            opacity: 0.8;
        }
        .edit {
            position: absolute;
            right: 6px;
            top: 6px;
            cursor: pointer;
        }
    }
    .order-items, .payment-items {
        display: flex;
        flex-direction: column;
        gap: 6px;
        overflow: auto;
        padding: 6px;
        max-height: 350px;
    }
    .payment-items {
        .item-container {
            justify-content: space-between;
        }
    }
    .item-container {
        display: flex;
        gap: 6px;
        .item-field {
            background-color: var(--main-box-color);
            border-radius: 5px;
            padding: 6px;
            flex-shrink: 0;
            min-height: 43px;
            &.error {
                color: var(--button-red);
                font-weight: bold;
            }
            .alig-left {
                text-align: left;
            }
            .label {
                font-size: 12px;
                opacity: 0.8;
            }
            .value {
                text-align: right;
            }
            &.name {
                min-width: 150px;
                max-width: 150px;
                overflow: hidden;
                .value {
                    text-align: left;
                }

            }
            &.category {
                min-width: 120px;
                max-width: 120px;
                overflow: hidden;
            }
        }
        .edit {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
    .check-item-group {
        display: flex;
        flex-direction: column;
        gap: 2px;
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 6px;
        &.valid {
            background-color: rgba(25, 162, 159, 0.1);
            border: 1px solid var(--button-green);
            color: var(--button-green);
        }
        &.invalid {
            background-color: rgba(222, 54, 54, 0.1);
            border: 1px solid var(--button-red);
            color: var(--button-red);
        }
        .check-item {
            display: flex;
            gap: 12px;
            .label {
                flex-shrink: 0;
                width: 150px;
                overflow: hidden;
            }
            .value {
                width: 50px;
                text-align: right;
                overflow: hidden;
            }
        }
    }
}
.ntak-payments-modal {
    .modal-content {
        gap: 12px
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .payment-container {
        display: flex;
        align-items: center;
        gap: 12px;
        .svg-root {
            cursor: pointer;
        }
    }
}