.app-roles-modal {
  .brand-block {
    border: 1px solid var(--lightgrey2);
    border-radius: 5px;
    padding: 0.5em;
    margin: 0 auto 0.5em;
    width: 100%;
  }
}
.reseller-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .reseller-btn {
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--ipanel-button-background-color);
    color: var(--ipanel-button-text-color);
    transition: all 0.3s;
    &.selected {
      background-color: var(--button-blue);
    }
    &.delete {
      background-color: var(--button-red);
    }
    &:hover {
      filter: brightness(1.2);
    }
  }
}
