.animated {
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    animation-fill-mode: none;
    -webkit-animation-fill-mode: none;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
@keyframes rotate {
    0% {
        /*transform: scale(1);*/
        transform-origin: center center;
        transform: rotate(-360deg) scale(1);
    }
    50% {
        /*transform: scale(1.1);*/
        transform-origin: center center;
        transform: rotate(-180deg) scale(0.70);
    }
    100% {
        /*transform: scale(1);*/
        transform-origin: center center;
        transform: rotate(0) scale(1);
    }
}
@-webkit-keyframes rotate {
    0% {
        /*-webkit-transform: scale(1);*/
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(-360deg) scale(1);
    }
    50% {
        /*-webkit-transform: scale(1.1);*/
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(-180deg) scale(0.70);
    }
    100% {
        /*-webkit-transform: scale(1);*/
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(0) scale(1);
    }
}
.rotate {
    animation-name: rotate;
    -webkit-animation-name: rotate;
}
@keyframes blinkWhite {
    from, to {
      color: transparent;
    }
    50% {
      color: white;
    }
}
.blinking-cursor-base {
    font-size: 12px;
    animation: 1s blinkWhite step-end infinite;
}