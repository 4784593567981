.item-selector-root {
    display: flex;
    height: 450px;
    position: relative;
    justify-content: center;
    .item-block {
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 5px;
        border: 1px solid var(--lightgrey2);
        padding: 0.5em;
        margin-bottom: 0.5em;
        cursor: pointer;
        user-select: none;
        &.selected, &:hover {
            color: #fff;
            background: var(--button-blue);
        }
        &.disabled{
            background-color: var(--lightgrey2);
            color:black;
            cursor: unset;
        }
    }
    .item-selector-inner {
        width: 260px;
        height: 100%;
        overflow: hidden;
        padding: 0 0.5em;
        position: relative;
        border:  1px solid var(--lightgrey2);
        border-radius: 5px;
        margin: 0 6px;
        .title {
            height: 32px;
            font-weight: bold;
        }
        .actions-container {
            position: absolute;
            top: 8px;
            right: 8px;
            height: 24px;
            cursor: pointer;
            border: 1px solid var(--lightgrey2);
            border-radius: 5px;
            min-height: unset;
            .action-btn {
                height: 100%;
                padding: 3px;
                margin: 0;
                display: flex;
                border-radius: 5px;
                &:hover {
                    color: #fff;
                    background: var(--button-blue);
                }
                .text {
                    padding-right: 5px;
                }
                svg {
                    height: 100%;
                }
            }
        }
        &.item-selector-selected-items {
            .actions-container {
                right: 8px;
                border: none;
                .action-btn {
                    &:hover {
                        color: #fff;
                        background: var(--button-blue);
                    }
                }
            }
        }
    }
    .list-root {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        overflow: auto;
        padding: 0.5em 0.5em 0.5em 0;
        margin: 0;
        height: calc(100% - 32px);
    }
}
.item-filter {
    display: flex;
    padding: 0 0.5em;
    gap: 0.5em;
    margin-bottom: 0.5em;
    .input-switch-root {
        margin: 0.5em 0;
        .label {
            padding-left: 0.5em;
        }
    }
}