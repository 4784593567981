.navigator-root {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    pointer-events: none;
    .navigator-layer {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: all;
    }
    .navigator-toggler {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 500;
        cursor: pointer;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 20px;
        pointer-events: all;
        .svg-root {
            svg {
                .svgFill {
                    fill: #F3F3F3;
                }
            }
        }
    }
    .navigator-content {
        pointer-events: all;
        margin-top: 64px;
        height: calc(100vh - 64px);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 400;
        overflow: hidden;
        //background-color: #eeeeee;
        //box-shadow: -6px 0px 15px 1px rgba(110,110,110,0.4);
        //border-top-left-radius: 10px;
        z-index: 11;
        .navigator-content-inner {
            max-width: 100vw;
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .navi-elements-container {
                padding: 0.5em 1em;
                user-select: none;
                flex: 0 0 auto;
                .navi-element-root {
                    display: flex;
                    min-height: 4em;
                    width: 278px;
                    .navi-element-btn {
                        padding: 0.5em 1em;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        margin: 0.5em 0 0.5em;
                        height: 3em;
                        width: 110px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        svg {
                            margin-right: 0.5em;
                            .svgFill {
                                fill: #fff;
                            }
                            .svgStroke {
                                stroke: #fff;
                            }
                        }
                    }
                    .navi-element {
                        .collapse-body, .collapse-header {
                            background-color: var(--main-box-color);
                            transition: background-color 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                        }
                        .collapse-header {
                            height: 3em;
                            padding: 0.5em;
                            margin: 0.5em 0.5em 0.5em 0;
                            width: 155px;
                            //min-width: 150px;
                            align-items: center;
                            .collapse-title {
                                overflow: hidden;
                                //padding-right: 18px;
                                height: 20px;
                                color: var(--ipanel-main-text-color);
                            }
                        }
                    }
                }
            }
            .nav-container {
                transition: all 0.5s;
                flex: 1 1 auto;
                .nav-element-group {
                    display: none;
                }
                .nav-element {
                    .nav-element-title {    
                        
                    }
                }
            }
        }
    }
    .navigator-content.open {
        width: 310px;
        transition: all 1s;
    }
    .navigator-content.closed {
        width: 0;
        transition: all 1s;
    }
}
.navi-sub-element {
    padding: 0.25em 0;
    cursor: pointer;
    padding: 0.5em;
}
.navi-sub-element:hover {
    background-color: var(--darkgrey);
    border-radius: 5px;
    color: var(--main-background-color);
}
.iPanel.reports {
    .navigator-root {
        .navigator-content {
            .navigator-content-inner {
                .navi-elements-container {
                    .navi-element {
                        .collapse-body, .collapse-header {
                            
                        }
                    }
                }
            }
        }
    }
}
.iPanel.vip {
    .navigator-root {
        .navigator-content {
            .navigator-content-inner {
                .navi-elements-container {
                    .navi-element {
                        .collapse-body, .collapse-header {
                            
                        }
                    }
                }
            }
        }
    }
}
.iPanel.brand {
    .navigator-root {
        .navigator-content {
            .navigator-content-inner {
                .navi-elements-container {
                    .navi-element {
                        .collapse-body, .collapse-header {
                            
                        }
                    }
                }
            }
        }
    }
}
.iPanel.global {
    .navigator-root {
        .navigator-content {
            .navigator-content-inner {
                .navi-elements-container {
                    .navi-element {
                        .collapse-body, .collapse-header {
                            
                        }
                    }
                }
            }
        }
    }
}