:root {
  //Panel colors
  --panel-bg-color: #27282c;
  --panel-text-color: #fff;

  //Main context colors
  --ipanel-main-text-color: #222222;
  --main-background-color: #f0f2f5;
  --main-box-color: #fff;

  //Modal
  --ipanel-border: #dedede;


  .dark-mode {
    --ipanel-main-text-color: #eceff1;
    --main-background-color: #1f2023;
    --main-box-color: #27282c;
    --ipanel-button-background-color: #383940;
    --ipanel-button-text-color: #eceff1;
    --ipanel-border: #383940;
    --inputField-highlight: #383940;
  }

  --module-color: #2e9bd9;
  .reseller {
    --module-color: #19a29f;
  }
  .global {
    --module-color: #d9382e;
  }
  .vip {
    --module-color: #d9802e;
  }

  --ipanel-brand-color: #2e9bd9;
  --ipanel-reseller-color: #19a29f;
  --ipanel-global-color: #d9382e;
  --ipanel-vip-color: #d9802e;

  --error-color: #ab0e0e;
  --lightgrey: #fbfbfb;
  --lightgrey2: #e2e2e2;
  --lightgrey3: rgba(173, 173, 173, 0.67);
  --grey: #838383;
  --mediumgrey: #646261;
  --darkgrey: #424242;
  --lightblue: #f1effc;

  // every input's bacground color
  --inputField-background-color: #ededed;
  // every input's highlight color AND selected color
  --inputField-highlight: #ededed;

  // button's text color AND on button SVG color
  --ipanel-button-text-color: #1b1c1e;
  --ipanel-button-background-color: #fff;
  --button-hover-text-color: #1b1c1e;
  --button-hover-background-color: #e6e6e6;

  // colored buttons
  --button-black: #1b1c1e;

  --button-blue: #2e9bd9;
  --button-blue-hover: #1d76a8;

  --button-red: #de3636;
  --button-red-hover: #c22121;

  --button-green: #19a29f;
  --button-green-hover: #12706e;

  --button-orange: #f48218;
  --button-orange-hover: #c27e3e;

  --button-lightblue: #f1effc;
  --button-lightblue-hover: #ccc8e4;

  --button-orange-gradient: linear-gradient(99.58deg, #ffab48 0.14%, #ff5c5c 98.71%);
  --button-orange-gradient-hover: linear-gradient(99.58deg, #d1862c 0.14%, #d84242 98.71%);

  --button-blue-gradient: linear-gradient(99.58deg, #48deff 0.14%, #735cff 98.71%);
  --button-blue-gradient-hover: linear-gradient(99.58deg, #3aabc4 0.14%, #402faa 98.71%);

  --button-black-gradient: linear-gradient(180deg, #686868 0%, #000000 100%);
  --button-black-gradient-hover: #000;

  --button-red-gradient: linear-gradient(99.58deg, #ff4848 0.14%, #8f0101 98.71%);
  --button-red-gradient-hover: linear-gradient(99.58deg, #ff4848 0.14%, #8f0101 98.71%);

  --button-green-gradient: linear-gradient(to right, #134e5e, #71b280);

  --button-yes: #58b22d;
  --button-yes-hover: #0c7209;

  --button-no: #de3636;
  --button-no-hover: #670e0e;

  --orange-gradient: linear-gradient(99.58deg, rgba(255, 171, 72, 0.3) 0.14%, rgba(255, 92, 92, 0.3) 98.71%);
  --blue-gradient: linear-gradient(99.58deg, rgba(72, 222, 255, 0.3) 0.14%, rgba(115, 92, 255, 0.3) 98.71%);

  // checkbox's color
  --button-checkbox-color: #e4e3e3;
}
