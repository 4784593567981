.collapse-root {
    width: 163px;
    .collapse-header {
        display: flex;
        padding: 0.5em;
        align-items: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        position: relative;
        cursor: pointer;
        .collapse-icon {
            height: 1em;
            margin-right: 8px;
        }
        .collapse-open-icon {
            display: none;
            height: 1em;
            position: absolute;
            right: 8px;
            svg {
                height: 100%;
            }
        }
    }
    .collapse-body {
        border-radius: 5px;
    }
}
.collapse-root.collapse-closed {
    .collapse-header {
        .collapse-open-icon {
            transform: rotate(180deg);
            transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    }
    .collapse-body {
        overflow: hidden;
        max-height: 0;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;   
    }
}
.collapse-root.collapse-open {
    .collapse-header {
        .collapse-open-icon {
            transform: rotate(0);
            transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    }
    .collapse-body {
        max-height: 2000px;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 0.5em 1em;
        margin-top: 0.5em;
    }
}