.circle-element-selector-root {
}

.circle-selector-root {
  position: absolute;
}
.palette {
    height: 200px;
    width: 200px;
    position:relative;
    margin:20px;
  }
  .palette > * {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border:50px solid var(--c,red);
    border-radius:50%;
    clip-path:polygon(50% 50%, 50% 0%, 100% 0%,100% 33.745%); 
  }
  .slice{
    --c:grey;
  }