.comment-div-root {
  display: flex;
  flex-direction: column;
  position: relative;
  border-left: solid 3px var(--main-box-color);
  padding: 8px;
  gap: 0.5em;
  .background {
    display: flex;
    height: 100%;
    width: 100%;
    top:0px;
    left:0px;
    opacity: 0.1;
    background-color: var(--main-box-color);
    position: absolute;
    z-index: -1;
  }
  .header {
    display: flex;
    .title {

    }
  }
  .text {
    display: flex;
  }
  &.orange {
    border-left-color: #ff8412;
    .background {
      background-color: #ff8412;
    }
  }
  &.red {
    border-left-color: #d9382e;
    .background {
      background-color: #d9382e;
    }
  }
  &.blue {
    border-left-color: #0c85d5;
    .background {
      background-color: #0c85d5;
    }
  }
  &.green {
    border-left-color: #64ba18;
    .background {
      background-color: #64ba18;
    }
  }
  &.teal {
    border-left-color: #19a29f;
    .background {
      background-color: #19a29f;
    }
  }
}
