.ntak-provider-modal {
    .modal-body {
        padding: 12px;
        border-radius: 5px;
        background-color: var(--main-box-color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        .files {
            display: flex;
            gap: 12px;
            .certificate-container {
                display: flex;
                flex-direction: column;
                gap: 6px;
                align-items: center;
                padding: 6px;
                border-radius: 5px;
                min-width: 130px;
                background-color: rgba(0, 0, 0, 0.05);
                border: 1px solid transparent;
                opacity: 0.5;
                transition: 0.5s all;
                &.valid {
                    background-color: rgba(25, 162, 159, 0.05);
                    border: 1px solid #19A29F;
                    opacity: 1;
                    .icon {
                        .svg-root {
                            svg {
                                .svgStroke {
                                    stroke: var(--button-green);
                                }
                            }
                        }
                    }
                    .text {
                        color: var(--button-green);
                    }
                }
                &.invalid {
                    opacity: 1;
                    .icon {
                        .svg-root {
                            svg {
                                .svgStroke {
                                    stroke: var(--button-red)
                                }
                            }
                        }
                    }
                    .text {
                        color: var(--button-red);
                    }
                }
            }
        }
        .file-upload-container {
            display: flex;
            gap: 12px;
            .upload {
                background-color: var(--button-blue);
                color: #fff;
                padding: 6px;
                border-radius: 5px;
                min-width: 130px;
                label {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    svg {
                        .svgFill {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
}

.ntak-orders-root {
    height: 100%;
}

.ntak-provider-box-root {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    width: 450px;
    background-color: var(--main-box-color);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    .header{
        display: flex;
        align-items: center;
        .title{
            display: flex;
            flex:1 1 auto;
        }
        .btn{
            border: unset;
            border-radius: 8px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
        }
    }
    .tags-container{
        display: flex;
    }
    .details-container{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .row{
            display: flex;
        }
    }
    .locations-container{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}

.ntak-day-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}