.view-root.mobile-devices {
  overflow: hidden;
  .view-actions {
    flex-wrap: wrap;
    padding: 0px 0 6px;
    gap: 6px;
    .btn {
      margin: 0;
    }
    .location-multi-select {
      width: 100%;
    }
    .search-field, .device-type-selector {
      width: calc(50% - 3px);
    }
    .actions-left {
      flex-wrap: wrap;
      gap: 6px;
      align-content: flex-start;
      height: unset;
    }
    .actions-right {
      height: unset;
    }
  }
  .devices-container {
      width: 100%;
      overflow: auto;
      padding: 12px 0 24px;
      flex: 1 1 auto;
      .swiper {
        margin-bottom: 16px;
      }
      .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 22px;
          font-weight: bold;
          color: #fff;
          height: 74px;
          .device-btn {
            width: 100%;
            height: 74px;
            text-align: center;
            border-radius: 10px;
            background-color: var(--main-box-color);
            display: flex;
            .left-side {
              width: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              .device-icon {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                .svg-root {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .right-side {
              flex: 1 1 auto;
              display: flex;
              flex-direction: column;
              .line-1 {
                display: flex;
                gap: 6px;
                align-items: center;
                .device-state {
                  position: relative;
                }
              }
            }
          }
          .device-action-btn {
            width: 48px;
            height: 48px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        
        .swiper-slide:nth-child(1n) {
          background-color: transparent;
          .device-action-btn {
            background-color: #D9382E;
          }
          width: 60px;
        }
        
        .swiper-slide:nth-child(2n) {
          width: 100%;
          background-color: transparent;
          
        }
        
        .swiper-slide:nth-child(3n) {
          background-color: transparent;
          .device-action-btn {
            background-color: #1F78B4;
          }
          width: 60px;
        }
  }
}