.brand-location-card {
    height: 200px;
    background: var(--main-box-color);
    border-radius: 5px;
    padding: 2em 1em 1em;
    margin: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    position: relative;
    .default-location {
        position: absolute;
        border-radius: 5px;
        top: -15px;
        width: 170px;
        text-align: center;
        padding: 0.5em;
    }
    .icons-container {
        font-size: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            height: 42px;
            margin: 0 8px;
        }
        .inactive {
            svg {
                opacity: 0.3;
            }
        }
    }
    .actions {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
            margin: 0 3px;
        }
    }
}