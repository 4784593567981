.input-field-select-main-container.textField-root {
  position: relative;
  &.slim{
    .textField-base{
      padding: 2px;
      &.align-center{
        padding: 2px;
      }
    }
  }
  .textField-base {
    cursor: pointer;
    display: block;
    padding: 27px 12px 10px;
    &.align-center{
      padding: 17px 12px 16px;
    }
  }  
}
.inputFieldSelectList {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow: auto;
  .list-element {
    cursor: pointer;
    padding: 1em;
    border-radius: 5px;
    &:hover {
      background-color: var(--inputField-highlight);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.selected{
      color: var(--module-color);
    }
  }
}
