.vip-item-editor {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: auto;
    padding-bottom: 24px;
    background-color: var(--main-box-color);
    padding: 12px 6px;
    .item-container {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 6px;
        border-radius: 5px;
        .item-name {
            flex: 1 1 auto;
        }
        &:hover {
            filter: brightness(1.2);
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            .add-btn {
                visibility: visible;
            }
        }
        .add-btn {
            visibility: hidden;
            cursor: pointer;
            background-color: var(--button-blue);
            padding: 5px 12px;
            border-radius: 5px;
            font-weight: bold;
            transition: filter 0.3s;
            &:hover {
                filter: brightness(1.2);
            }
        }
    }
}