.brand-ntak-provider-root {
  .view-content {
    display: flex;
    padding: 6px;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: auto;
    gap: 12px;
  }
}

.ntak-providers-create-new-provider-modal-root {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  .header {
    gap: 12px;
    display: flex;
    align-items: center;
    .title {
      display: flex;
      flex: 1 1 auto;
    }
    .action-btns {
      display: flex;
      flex: 0 0 auto;
      gap: 8px;
    }
  }
}
.create-new-provider-step-contorls-footer-root {
  display: flex;
  width: 100%;
  .action-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btn-conatiner {
      display: flex;
      .btn {
        display: flex;
      }
    }
  }
}
.ntak-providers-create-new-provider-modal-step-root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 600px;
  overflow: hidden;
  .title {
  }
  .btn-container {
    display: flex;
    gap: 12px;
  }
  .download-text {
    display: block;
    .text {
      a {
        text-decoration: unset;
      }
    }
  }
  .text {
    a {
      color: var(--button-blue);
      text-decoration: underline;
    }
  }
  .text-fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.schedule-planner-root {
  user-select: none;
  .closed-day {
    cursor: pointer;
    border: 1px solid var(--ipanel-border);
    display: flex;
    justify-content: center;
    padding: 0.1em;
    border-radius: 8px;
    &.selected {
      background-color: var(--button-blue);
      color: #fff;
    }
  }
}
