.integration-schema-root {
    .item-editor-categories-root {
        .category-list {
            .enabled-container {
                display: flex;
                flex-direction: column;
                gap: 12px;
                .schema-button-container {
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 6px 12px;
                    background-color: rgba(0, 0, 0, 0.2);
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.4);
                    }
                    &.selected {
                        transition: all 0.3s;
                        color: #fff;
                        background-color: var(--module-color);
                    }
                }
            }
        }
    }
    .selected-schema {
        max-width: 400px;
        background-color: rgba(0, 0, 0, 0.2);
        position: relative;
        border-radius: 5px;
        padding: 6px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        .btn {
            position: absolute;
            top: 6px;
            right: 6px;
        }
        .params {
            
        }
    }
}