.customer-panel-root {
    .customer-panel-actions {
        .textField-root {
            width: 400px;
            margin-right: 1em;
            .textField-base {
                background-color: var(--main-box-color);
            }
        }
    }
    .customer-panel-data {
        overflow: auto;
        .okeokeDataTable {
            .okeokeDataTable-inner-container {
                .dataColumns {
                    .dataColumn.vipID {
                        .dataCell.vipID {
                            .customer-panel-vipID:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}