.okeoke-purchase-info-box-main-container {
  max-width: 1000px;
  height: calc(90vh - 2em);
  .header {
    font-size: 1.5rem;
  }
  .transaction-details {
    display: flex;
    flex-wrap: wrap;
    .data-block {
      display: flex;
      font-weight: bold;
      margin: 1em 2em 1em 0;
      .label {
        min-width: 150px;
        opacity: 0.6;
      }
      .value {
        min-width: 300px;
      }
    }
  }
  .data-table-root {
    .data-table-header {
      margin-bottom: 0.5em;
    }
    .data-table-items{
        &.okeokeDataTable{
          background: var(--orange-gradient);
          box-shadow: none;
          .titleCell.itemName {
            text-align: left;
          }
          .dataCell.itemName {
            justify-content: flex-start;
          }
        }
    }
    .data-table-transactions{
        &.okeokeDataTable{
          background: var(--blue-gradient);
          box-shadow: none;
          .titleCell.transactionTypeID {
            text-align: left;
          }
          .dataCell.transactionTypeID {
            justify-content: flex-start;
          }
        }
    }
  }
  .data-table-root.items {
    height: 350px;
    overflow: auto;
  }
  .data-table-root.transactions {

  }
}
