.view-root.location-openhours-template-editor {
    padding: 2em;
    .location-icon {
        height: 3em;
        width: 3em;
        margin-right: 1em;
        .svg-root {
            height: 3em;
            width: 3em;
        }
    }
    .location-header {
        color: var(--grey);
        margin-right: 1em;
        min-width: 180px;
        .location-name {
            font-size: 24px;
        }
        .location-open-hours {
            display: flex;
            .label {
                margin-right: 6px;
            }
        }
    }
    .location-open-state {
        color: #fff;
        padding: 6px 24px;
        border-radius: 300px;
        text-transform: uppercase;
        font-weight: bold;
        &.open {
            background: var(--button-green)
        }
        &.closed {
            background: var(--button-red)
        }
    }
    .actions-right {
        .btn {
            padding: 1em 2em;
            height: 3em;
            .btn-text {
                text-transform: uppercase;
                font-size: 24px;
            }
        }
    }
    .view-content {
        display: flex;
        border-radius: 5px;
    }
}
.location-openhours-template-selector {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .actions {
        height: 4em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
            margin-left: 0.5em;
        }
    }
    .content {
        display: flex;
        flex: 1 1 auto;
        border-radius: 5px;
        overflow: auto;
        padding: 12px;
        .days-container {
            background: var(--main-box-color);
            border: 1px solid var(--ipanel-border);
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding: 0.5em;
            height: 100%;
            min-width: 150px;
            overflow: auto;
        }
        .day-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 3.5em;
            padding: 0.5em;
            font-weight: bold;
            user-select: none;
            border-radius: 5px;
            &.selected {
                color: #fff;
                background: var(--button-blue)
            }
            &.closed {
                background: var(--button-red)
            }
        }
        .template-container {
            height: 100%;
            border: 1px solid var(--ipanel-border);
            padding: 0.5em;
            border-right: none;
            border-left: none;
            min-width: 180px;
            background: var(--main-box-color);
            .selected-template {
                height: 4em;
                .template-btn {
                    background: var(--button-green);
                    color: #fff;
                }
            }
            .template-list {
                height: calc(100% - 4em);
                overflow: auto;
            }
        }
        .selected-template-container {
            min-width: 800px;
            border: 1px solid var(--ipanel-border);
            border-radius: 5px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 0.5em;
            background: var(--main-box-color);
            .header {
                display: flex;
                justify-content: space-between;
                height: 4em;
            }
            .open-hours {
                height: 4em;
            }
        }
        .calendar-container {
            width: 70%;
            height: 100%;
            border: 1px solid var(--ipanel-border);
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            min-width: 600px;
            overflow: hidden;
            padding: 8px;
            user-select: none;
            position: relative;
            .calendar-actions {
                position: absolute;
                top: 12px;
                right: 12px;
                .set-today {
                    cursor: pointer;
                    background: var(--lightgrey3);
                    border-radius: 100px;
                    padding: 4px 12px;
                    display: flex;
                }
            }
            .date-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 4em;
                width: 100%;
                padding: 0 0.5em 0;
                .year-container, .month-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    height: 60px;
                    position: relative;
                    .step-up, .step-down {
                        position: absolute;
                        left: 50%;
                        top: 0;
                        width: 100%;
                        transform: translateX(-50%);
                        height: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            height: 14px;
                            width: 14px;
                        }
                        .svgText {
                            stroke: #000;
                        }
                    }
                    .step-down {
                        top: auto;
                        bottom: -1px;
                        transform: translateX(-50%) rotate(180deg);
                    }
                }
                .month-container {
                    width: 110px;
                }
                .year-container {
                    width: 54px;
                }
            }
            .calendar-days-container {
                display: flex;
                width: 100%;
                height: 2em;
                .day {
                    width: calc(100%/7);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 2em;
                }
            }
            .calendar-dates-container {
                height: calc(100% - 6em);
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                gap: 2px;
                overflow: auto;
                padding: 6px;
                .date-cell {
                    border-radius: 6px;
                    background: var(--lightgrey3);
                    border: 3px solid transparent;
                    cursor: pointer;
                    //min-height: 90px;
                    //min-width: 90px;
                    text-align: right;
                    position: relative;
                    .day-number {
                        opacity: 0.3;
                        position: absolute;
                        top: 6px;
                        right: 8px;
                        color: #000;
                    }
                    &.selected {
                        border: 3px solid var(--button-red);
                    }
                    &.actual-month {
                        background: var(--lightgrey2);
                        .day-number {
                            opacity: 1;
                        }
                    }
                    &:hover {
                        background: var(--lightgrey);
                        border: 3px solid var(--lightgrey2);
                        &.selected {
                            border: 3px solid var(--button-red);
                        }
                    }
                    .open-hours-container {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 70%;
                        overflow: hidden;
                        .range {
                            display: flex;
                            justify-content: center;
                            background: var(--button-green);
                            border-radius: 5px;
                            margin-bottom: 4px;
                            color: #fff;
                            &.closed {
                                background: var(--button-red);
                            }
                        }
                        &.unique {
                            .range {
                                background: var(--button-orange)
                            }
                        }
                    }
                }
            }
        }
        .selected-day-container {
            width: 30%;
            min-width: 250px;
            overflow: auto;
            border: 1px solid var(--lightgrey2);
            border-radius: 5px;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            .day-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 6em;
                padding: 0.5em 1em 3em;
                width: 100%;
                .selected-day {
                    font-style: italic;
                }
            }
            .selected-day-templates-container {
                padding: 1em;
                .range {
                    display: flex;
                    margin: 0.5em 0 0;
                }
                .btn {
                    margin-top: 1em;
                }
                .selected-template {
                    overflow: hidden;
                    border: 1px solid var(--lightgrey2);
                    border-radius: 5px;
                    padding: 0.5em;
                    min-height: 150px;

                }
                .add-template-container {
                    overflow: hidden;
                    border: 1px solid var(--lightgrey2);
                    border-radius: 5px;
                    padding: 0.5em;
                    .textField-root {
                        margin-top: 1em;
                    }
                }
            }
        }
    }
}
.month-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 3.5em;
    padding: 0.5em;
    font-weight: bold;
    user-select: none;
    border-radius: 5px;
    &:hover {
        background: var(--button-blue);
        color: #fff;
    }
}