.icon-selector-container {
    height: 50px;
    width: 50px;
    overflow: hidden;
    display: inline-flex;
    cursor: pointer;
    background-color: var(--app-editor-brand-app-icon-bg-color);
    padding: 6px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    .svg-root {
        width: 32px;
        height: 32px;
        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            .svgFill {
                fill: var(--app-editor-brand-app-icon-color);
            }
            .svgStroke {
                stroke: var(--app-editor-brand-app-icon-color);
            }
        }
    }
}
.icon-select-modal {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    .svg-container {
        height: 3.5em;
        width: 3.5em;
        overflow: hidden;
        display: inline-flex;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 6px;
        border-radius: 5px;
        margin: 6px;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }
        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            .svgFill {
                fill: #fff;
            }
            .svgStroke {
                stroke: #fff;
            }
        }
    }
}