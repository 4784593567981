.create-brand-wizard-root {
  margin: auto;
  background-color: #fff;
  padding: 24px;
  border-radius: 5px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  .input-label {
    text-align: center;
    margin-top: 12px;
  }
  input {
    color: #000;
    border: none;
    border-bottom: 1px solid #3aabc4;
    margin: 12px;
    padding: 6px 12px;
  }
}
.progress-bar-root {
  display: flex;
  justify-content: space-between;
  position: relative;
  .stage-container {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    margin: 6px;
    text-align: center;
    border: 1px solid #3aabc4;
    border-radius: 200px;
    color: #000;
    transition: all 0.3s;
    &.selected {
      color: #fff;
      background-color: #3aabc4;
      .stage-circle {
        transition: all 0.3s;
        background: #1648b5;
      }
    }
    .stage-name {
    }
    .stage-circle {
      transition: all 0.3s;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      border: 5px solid #1648b5;
      background: #fff;
    }
  }
  .horizontal-line {
    z-index: 1;
    height: 5px;
    background: #dfe1e6;
    position: absolute;
    bottom: 30px;
    left: 2%;
    width: 95%;
  }
}
.stages-container {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: auto;
  .stage-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    flex-direction: column;
    overflow: auto;
    padding: 1em;
    width: 100%;
    height: 100%;
    .textField-root {
      margin: 0.5em;
    }
  }
}
.summary-root {
  width: 100%;
  height: 100%;
  padding: 2em;
  .data-summary-box {
    .data-title {
      margin-bottom: 1em;
      color: #3aabc4;
    }
    .data-fields {
      padding-bottom: 0.5em;
      margin-bottom: 1em;
      border-bottom: 1px solid #3aabc4;
      .data-field {
        display: flex;
        margin-bottom: 0.5em;
        .data-field-value {
          padding-left: 6px;
        }
      }
    }
  }
}
.done-container {
  text-align: center;
}
.actions-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;
  user-select: none;
  .btn {
    font-size: 20px;
    margin: 0 26px;
    background-color: #3aabc4;
    border: none;
  }
  .back-btn {
    font-size: 20px;
    cursor: pointer;
  }
}
