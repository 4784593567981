.textField-root {
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  overflow: hidden;
  border-radius: 5px;
  min-width: 130px;
  border: 1px solid #e1e1e1;
  &.focused {
    border-color: var(--module-color);
  }
  &.date-picker-input {
    .textField-base {
      input {
        cursor: pointer;
      }
    }
  }
  .textField-label {
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    //color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    letter-spacing: 0.00938em;
    //text-transform: uppercase;
    z-index: 0;
    transform: translate(12px, 20px) scale(1);
    pointer-events: none;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: max-content;
  }
  .textField-label.shrink {
    transform: translate(12px, 10px) scale(0.75);
    transform-origin: top left;
  }
  .textField-base {
    position: relative;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: var(--ipanel-main-text-color);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1em;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    .textField-input {
      font: inherit;
      color: currentColor;
      width: 100%;
      border: 0;
      height: 1.1876em;
      margin: 0;
      display: block;
      padding: 6px 0 7px;
      min-width: 0;
      background: none;
      box-sizing: content-box;
      animation-name: mui-auto-fill-cancel;
      letter-spacing: inherit;
      animation-duration: 10ms;
      -webkit-tap-highlight-color: transparent;
      padding: 27px 12px 10px;
      border-radius: 5px;
    }
    .btn {
      height: 3.5em;
      opacity: 0.6;
      min-width: 45px;
    }
  }
  .textField-helperText {
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 0.75em;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-left: 14px;
    margin-right: 14px;
    height: 2em;
  }
  .textField-helperText.helperTextError {
    color: var(--error-color);
  }
}
.textField-root.fullWidth {
  width: 100%;
}
.textField-root.marginNormal {
  margin-top: 16px;
  margin-bottom: 8px;
}
.textField-root.disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
  .textField-label {
    color: rgba(0, 0, 0, 0.3);
  }
  .textField-base {
    opacity: 0.5;
  }
}
.textField-root.with-icon {
  .textField-base {
    .icon-container {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;
    }
    input {
      width: calc(100% - 64px);
    }
  }
  &.icon-start {
    .textField-label {
      padding-left: 40px;
    }
  }
}
.textField-root.small {
  height: 2em;
  .textField-label {
    top: 50%;
    transform: translateY(-50%);
    padding-left: 1em;
  }
  .textField-label.shrink {
    display: none;
  }
  .textField-base {
    display: flex;
    .icon-container {
      width: 40px;
    }
    input {
      padding: 0 0 0 1em;
      height: 2em;
      width: calc(100% - 40px);
    }
  }
}
.textField-root.search-field {
  border-radius: 5px;
  .textField-base {
    background-color: var(--main-box-color);
    border-radius: 5px;
  }
}
.multilang-modal {
  .textField-base {
    margin: 0.5em;
  }
}
.dark-mode {
  .textField-root {
    border-color: #383940;
    &.focused {
      border-color: var(--module-color);
    }
    &.disabled {
      .textField-label {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
