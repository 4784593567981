.text-to-svg-root{
    height: 100%;
    width: 100%;
    svg{
        height: 100%;
        width: 100%;
        max-height: 1.5em;
        .svgStroke{
            stroke: black;
        }
        .svgFill{
            fill: grey;
        }
    }
}