input[type=date] {
    border: none;
    font-size: 1rem;
    font-family: helvetica;
    height: 3.35em;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 0.5em;
}
input[type=datetime-local] {
    border: none;
    font-size: 1rem;
    font-family: helvetica;
    height: 3.35em;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 0.5em;
}