.iPanel {
  display: flex;
  width: 100%;
  height: 100%;
  color: var(--panel-text-color);
  background-color: var(--panel-bg-color);
  transition: background-color 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
  font-family: 'Barlow', sans-serif;
  .main-left-side {
    height: 100%;
    width: 190px;
    transition: width 1s;
    user-select: none;
    .nav-container {
      height: 100%;
    }
  }
  &.minimalized {
    .main-left-side {
      width: 45px;
      transition: width 1s;
      .corner-cell {
        flex-direction: column;
        padding: 0;
        .svg-container {
          margin-right: 0;
        }
        .corner-text {
          font-size: 12px;
          transition: font-size 1s;
        }
      }
      .nav-element-outer {
        .sub-element-toggler {
          display: none;
        }
        .sub-elements {
          max-height: 0;
        }
      }
      .nav-element {
        .nav-element-title {
          display: none;
        }
      }
      .nav-container {
        padding-top: 6em;
        transition: all 1s;
        .nav-toggler {
          transition: all 1s;
          top: 43px;
          right: 3px;
        }
        .nav-element.selected {
          padding: 0;
        }
      }
    }
    .main-right-side {
      .iPanel-text {
        left: 10px;
        transition: all 1s;
      }
      .top-bar {
        padding-left: 75px;
        transition: all 1s;
      }
    }
  }
  &.navi-open {
    .main-right-side {
      .main-context {
        width: calc(100% - 310px);
        transition: all 1s;
      }
    }
  }
  &.navi-closed {
    .main-right-side {
      .main-context {
        width: calc(100% - 10px);
        transition: all 1s;
      }
    }
  }

  .main-right-side {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 190px);
    transition: width 1s;
    .iPanel-text {
      cursor: pointer;
      position: absolute;
      left: 40px;
      top: 2px;
      font-size: 32px;
      font-weight: bold;
      font-family: Mulish;
      font-weight: 900;
      transition: all 1s;
    }
    .top-bar {
      display: flex;
      padding-left: 20px;
      height: 3em;
      align-items: center;
      position: relative;
      width: 100%;
      transition: all 1s;
      .breadcrumb {
        width: 500px;
      }
      .user-data {
        position: absolute;
        right: 40px;
        width: calc(100% - 570px);
        text-align: right;
        overflow: hidden;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .user-text {
          height: 34px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
      }
      .text {
        font-style: normal;
        font-weight: 600;
        margin: 0 2px;
      }
      .text-menu, .text-module {
        text-transform: capitalize;
      }
    }
    .main-context {
      overflow: hidden;
      height: calc(100% - 3em);
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: var(--main-background-color);
      color: var(--ipanel-main-text-color);
    }
  }
  &.minimalized {
    .main-right-side {
      width: calc(100% - 45px);
      transition: width 1s;
    }
  }
}
.flag-container {
  height: 16px;
  cursor: pointer;
  img {
    height: 100%;
    border-radius: 3px;
  }
}
.iPanel.vip {
  .main-right-side {
    .top-bar {
      .text-module {
        color: var(--module-color);
        filter: brightness(1.2);
        text-shadow: 0 0 5px var(--module-color);
        // background-clip: text;
        // -webkit-background-clip: text;
        // color: transparent;
        // -webkit-text-fill-color: "transparent"
      }
    }
  }
}
.iPanel.brand {
  .main-right-side {
    .top-bar {
      .text-module {
        color: var(--module-color);
        filter: brightness(1.2);
        text-shadow: 0 0 5px var(--module-color);
      }
    }
  }
}
.iPanel.global {
  .main-right-side {
    .top-bar {
      .text-module {
        color: var(--module-color);
        filter: brightness(1.2);
        text-shadow: 0 0 5px var(--module-color);
      }
    }
  }
}
.iPanel.reseller {
  .main-right-side {
    .top-bar {
      .text-module {
        color: var(--module-color);
        filter: brightness(1.2);
        text-shadow: 0 0 5px var(--module-color);
      }
    }
  }
}