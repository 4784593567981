.content-body-container.locations-dashboard {
    .locations-container {
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        .location-button-container {
            display: flex;
            align-items: center;
            gap: 6px;
            box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            background-color: var(--main-box-color);
            width: 90%;
            padding: 12px;
            border: 1px solid transparent;
            &.selected {
                border: 1px solid var(--module-color);
            }
            .location-icon {
                display: flex;
                align-items: center;
            }
        }
    }
}