.brand-tag-root {
  display: flex;
  flex: 0 1 auto;
  padding: 0.3em 0.5em;
  gap: 0.2em;
  align-self: flex-start;
  background-color: #F5F5F5;
  color: var(--ipanel-button-text-color);
  border-radius: 50px;
  border: solid 1px var();
  align-items: center;
  svg {
    height: 15px;
    width: 15px;
    .svgFill {
      fill: var(--ipanel-button-text-color);
    }
    .svgStroke {
      stroke: var(--ipanel-button-text-color);
    }
  }
  .brand-tag-text {
    place-self: center;
  }
  &.red {
    background-color: #fef2f2;
    color: #d9382e;
    .svgFill {
      fill: #d9382e;
    }
    .svgStroke {
      stroke: #d9382e;
    }
  }
  &.green {
    background-color: #f5ffec;
    color: #64ba18;
    .svgFill {
      fill: #64ba18;
    }
    .svgStroke {
      stroke: #64ba18;
    }
  }
  &.cyan {
    background-color: #f5ffec;
    color: #0F6959;
    .svgFill {
      fill: #0F6959;
    }
    .svgStroke {
      stroke: #0F6959;
    }
  }
  &.orange {
    background-color: #fff8f2;
    color: #ff8412;
    .svgFill {
      fill: #ff8412;
    }
    .svgStroke {
      stroke: #ff8412;
    }
  }
  &.purple {
    background-color: #f9f2ff;
    color: #985cd3;
    .svgFill {
      fill: #985cd3;
    }
    .svgStroke {
      stroke: #985cd3;
    }
  }
  &.yellow {
    background-color: #fffbeb;
    color: #f59e0b;
    .svgFill {
      fill: #f59e0b;
    }
    .svgStroke {
      stroke: #f59e0b;
    }
  }
  &.blue {
    background-color: #eff6ff;
    color: #2563eb;
    .svgFill {
      fill: #2563eb;
    }
    .svgStroke {
      stroke: #2563eb;
    }
  }
  &.grey{
    background-color: #F4F4F5;
    color: #71717A;
    .svgFill {
      fill: #71717A;
    }
    .svgStroke {
      stroke: #71717A;
    }
  }
}

.dark-mode {
  .brand-tag-root {
    background-color: #3f3f46;
    &.red {
      background-color: #3f3f46;
      color: #f87171;
      svg {
        .svgFill {
          fill: #f87171;
        }
        .svgStroke {
          stroke: #f87171;
        }
      }
    }
    &.green {
      background-color: #3f3f46;
      color: #64ba18;
      .svgFill {
        fill: #64ba18;
      }
      .svgStroke {
        stroke: #64ba18;
      }
    }
    &.cyan {
      background-color: #3f3f46;
      color: #20D6B5;
      .svgFill {
        fill: #20D6B5;
      }
      .svgStroke {
        stroke: #20D6B5;
      }
    }
    &.orange {
      background-color: #3f3f46;
      color: #f59e0b;
      .svgFill {
        fill: #f59e0b;
      }
      .svgStroke {
        stroke: #f59e0b;
      }
    }
    &.purple {
      background-color: #3f3f46;
      color: #B379EC;
      .svgFill {
        fill: #B379EC;
      }
      .svgStroke {
        stroke: #B379EC;
      }
    }
    &.yellow {
      background-color: #3f3f46;
      color: #FBBF24;      
      .svgFill {
        fill: #FBBF24;
      }
      .svgStroke {
        stroke: #FBBF24;
      }
    }
    &.blue {
      background-color: #1D4ED8;
      color: #93C5FD;
      .svgFill {
        fill: #93C5FD;
      }
      .svgStroke {
        stroke: #93C5FD;
      }
    }
    &.grey{
      background-color: #3f3f46;
      color: #E4E4E7;
      .svgFill {
        fill: #E4E4E7;
      }
      .svgStroke {
        stroke: #E4E4E7;
      }
    }
  }
}
