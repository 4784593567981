.welcome-demo-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .left-col {
        width: 600px;
    }
    .right-col {
        width: 400px;
        padding: 2em;
    }
}
.brandApp-container {
    background: var(--main-bg-color);
    border-radius: 5px;
    font-size: 16px;
    overflow: hidden;
    .not-selected-component {
        //border: 2px dashed transparent;
        cursor: pointer;
    }
    .selected-component {
        border: 2px dashed #000;
    }
    .main-content {
        height: 500px;
        overflow: auto;
        .slider-container {
            width: 100%;
            height: 0;
            padding-bottom: 66%;
            max-height: 400px;
            margin-bottom: 1em;
            background: #eeeeee;
        }
        .nav-buttons-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            overflow: hidden;
        }
    }
}