.location-payment-agents {
    width: 100%;
    height: 100%;
    .selected-agents {
        height: calc(100% - 72px);
        width: 100%;
        overflow: hidden;
        padding: 1em;
        .okeokeDataTable {
            .dataColumns {
                .dataColumn {
                    width: calc((100% - 200px) / 4);
                    .dataCell {
                        svg {
                            height: 50%;
                        }
                    }
                    &.selectedAgents {
                        text-align: left;
                        width: 200px;
                    }
                }
            }
        }
        .select {
            width: 48px;
            height: 24px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            &.selected {
                background: var(--button-green);
            }
            &.not-selected {
                border: 2px dashed var(--button-green);
                &:hover {
                    background: var(--button-green);
                }
            }
        }
    }
    .payment-agents-container {
        display: flex;
        overflow: auto;
        .agent-btn {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: var(--button-blue);
            color: var(--ipanel-button-text-color);
            padding: 0.25em 1em;
            border-radius: 5px;
            height: 46px;
            margin: 0.5em;
            user-select: none;
            cursor: pointer;
            &:hover {
                background: var(--button-blue-hover);
            }
        }
    }
}