@media screen and (orientation:portrait) {
    .modal.user-modal {
        .modal-content {
            height: calc(100% - 50px);
            max-height: calc(100% - 50px);
            .user-modal {
                
                .nav-container {
                    flex: 1 1 auto;
                    padding: 0;
                    display: block;
                    overflow: auto;
                    .sub-elements {
                        .nav-element.selected {
                            .nav-element-title {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
    .clickable {
        &.mobile {
          display: flex;
        }
        &.desktop {
            display: none;
        }
    }
    .iPanel {
        .main-left-side {
            display: none;
        }
        .main-right-side {
            width: 100%;
            .top-bar {
                padding: 0 0.5em;
                .breadcrumb {
                    width: 90%;
                    .text {
                        font-size: 15px;
                    }
                }
                .user-data {
                    display: none;
                }
                .text {
                    font-size: 18px;
                }
            }
        }
    }
    .iPanel-text {
        display: none;
    }
    .iPanel.navi-open {
        .main-right-side {
            .main-context {
                opacity: 0.3;
            }
        }
    }
    .iPanel.navi-closed {
        .main-right-side {
            .main-context {
                width: 100%;
            }
        }
    }
    .login-screen {
        position: relative;
        .left-side, .right-side {
            width: 100%;
        }
        .left-side {
            img {
                display: none;
            }
        }
        .right-side {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            .text {
                color: #fff;
            }
            .text-large {
                margin-top: 0;
            }
            .login-fields {
                background-color: var(--main-box-color);
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1em;
                border-radius: 5px;
                padding: 2em 0.5em;
            }
        }
    }
    .navigator-root {
        .navigator-content {
            .navigator-content-inner {
                .nav-container {
                    padding: 0em 8px 8px;
                    .nav-element-group {
                        display: flex !important;
                        flex-grow: unset;
                        flex-direction: column;
                        overflow: auto;
                    }
                    .nav-element {
                        padding: 0.5em;
                        height: 3em;
                        margin-bottom: 0;
                        .svg-container {
                            svg {
                                .svgFill {
                                    fill: #fff;
                                }
                                .svgStroke {
                                    stroke: #fff;
                                }
                            }
                        }
                        .nav-element-title {    
                            color: #fff;   
                        }
                    }
                    .nav-element.selected {
                        border-radius: 5px;
                        .nav-element-title {
                            color: #fff;
                        }
                        svg {
                            .svgFill {
                            fill: #fff;
                            }
                            .svgStroke {
                            stroke: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    .view-root {
        padding: 0;
        .view-actions {
            padding: 0 1em;
        }
        .view-content {
            justify-content: center;
        }
    }
    .modal {
        .modal-content {
            max-width: 95vw;
            .modal-header {
                flex-direction: column;
                padding-bottom: 0.5em;
                margin-bottom: 1em;
                .modal-title {
                    margin-bottom: 0.5em;
                }
                .modal-actions {
                    width: 100%;
                    overflow: auto;
                    padding: 0 1em 1em;
                    justify-content: center;
                }
            }
        }
    }
    .item-settings-box {
        &.double {
            width: 300px;
        }
    }
    .selected-item-editor-root {
        .view-content {
            flex-direction: row;
        }
    }
    .dashboard-root {
        justify-content: center;
        padding: 8px;
        .dashboard-chart-container {
           //display: none;
        }
        .box-group {
            width: 100%;
        }
        .dashboard-box {
            width: 100%;
            margin: 8px;
            overflow: auto;
            .title {
                font-size: 18px;
            }
            &.small {
                width: 45%;
                .label {
                    font-size: 16px;
                }
                .value {
                    font-size: 26px;
                }
            }
            .okeoke-data-table {
                font-size: 14px;
            }
        }
    }
    .item-editor-items-root.full-width {
        padding: 0;
    }
    .view-actions.item-editor-items-actions {
        padding-left: 0.5em;
    }
    .item-editor-items-content {
        width: 100%;
    }
    .catalog-editor-root.brand-devices-root {
        .selected-device {
            flex-direction: row;
        }
        .device-data-box {
            width: 100%;
            margin: 1em 0;
            overflow: hidden;
        }
    }
    .location-card {
        width: 100%;
    }
}