.small-input-field {
    height: 25px;
    display: flex;
    align-items: center;
    input {
        -webkit-tap-highlight-color: transparent;
        border: none;
        background:rgba(0, 0, 0, 0.09);
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        width: 50px;
        text-align: right;
        padding: 3px 3px 3px 12px;
        height: 100%;;
    }
    .post-fix {
        background:rgba(0, 0, 0, 0.09);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        padding: 4px 16px 3px 3px;
        text-align: left;
        height: 100%;
        width: 32px;;
    }
    .label {
        margin-right: 6px;
    }
}