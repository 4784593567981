.brand-order-info-content {
  .active-tab {
    width: 800px;
    height: 600px;
  }
}
.modal.order-status-notes-modal {
   .modal-content {
      max-width: 800px;
      .notes {
         width: 100%;
         overflow: auto;
      }
   }
}
.brand-orders-actions {
   .input-switch-root {
      width: 180px;
      margin: 0 12px;
   }
}