.edit-template-root {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--ipanel-border);
    .font-extraLarge {
      display: flex;
      flex: 1 1 auto;
    }
    .action-buttons-right-side {
      display: flex;
      gap: 10px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    .date-boxes{
        display: flex;
    }
    .action-buttons{
        display: flex;
    }
    .daily-availability-root {
        max-width: 450px;
      .intervals {
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
      }
    }
  }
}
