.main-mobile-content {
    .content-footer-container {
        background-color: #17151F;
        position: relative;
        height: 70px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        z-index: 1;
        .circle-container {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            height: 70px;
            background-color: #17151F;
            overflow: hidden;
            .bg-circle {
                width: 90px;
                height: 90px;
                background-color: var(--module-color);
                border-radius: 100%;
                position: absolute;
                opacity: 0.5;
                filter: blur(60px);
                z-index: 1;
                &.bg-circle-left {
                    bottom: 10px;
                    left: -42px;
                }
                &.bg-circle-right {
                    bottom: -55px;
                    right: -40px;
                }
            }
        }
        .nav-element-container {
            display: flex;     
            justify-content: space-between;
            gap: 12px;
            align-items: flex-end;
            position: absolute;
            bottom: 16px;
            padding: 0 12px;
            .selected-circle {
                position: absolute;
                left: 0;
                bottom: 25px;
                background-color: var(--module-color);
                border-radius: 100%;
                width: 50px;
                height: 50px;
                z-index: 1;
                transition: all 0.3s;
                box-shadow: 0px 0px 8.8421px var(--module-color);
            }
            .nav-element {
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 2;
                
                &.animated-nav-select {
                    animation: float-select 0.3s forwards ease-out;
                }
                &.animated-land {
                    animation: land 0.3s forwards ease-out;
                }
                .svg-root {
                    width: 30px;
                    height: 30px;
                    .svgFill {
                        fill: #ECEFF1;
                    }
                    .svgStroke {
                        stroke: #ECEFF1;
                    }
                }
            }
        }
    }
}