.payment-agents-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-content: flex-start;
    .agent-outer-container {
        border: 2px solid var(--main-box-color);
        padding: 12px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.05);
        .agent-container {
            .agent-header {
                font-size: 18px;
                margin-bottom: 6px;
            }
            .agent-body {
                .agent-type-code {
                    margin-bottom: 6px;
                }
            }
        }
    }
}