.text-value-root {
  display: flex;
  .container {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 5px;
    gap: 5px;
    .name {
      font-weight: 600;
    }
    .value {
      border: none;
      border-radius: 6px;
      padding: 5px;
    }
  }
  .selector-container {
    display: flex;
    margin-left: 2em;
    overflow: auto;
    gap: 5px;
    .selectable {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding: 0px 0.3em;
      height: 1.5em;
      cursor: pointer;
      border: 1px solid var(--module-color);
      &.selected {
        background-color: var(--module-color);
        color: white;
      }
    }
  }
}
