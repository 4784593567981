.online-payment-wizard {
    width: 450px;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .wizard-step {
        height: calc(100% - 28px);
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow: auto;
        .title {
            font-size: 16px;
            text-align: center;
        }
        .choose {
            min-width: 100px;
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: center;
            .option {
                cursor: pointer;
                &.selected {
                    border-bottom: 1px solid #000;
                    font-weight: bold;
                }
            }
        }
        .add-new-profile-container {
            display: flex;
            gap: 12px;
            width: 80%;
            margin: auto;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: flex-start;
        }
        .selected-option {
            min-width: 300px;
            text-align: center;
            .device-data-box {
                max-width: 100%;
                margin: 12px auto;
                text-align: left;
            }
            .online-profile-container {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                align-items: center;
                width: 80%;
                margin: auto;
                .profile-card {
                    border: 2px solid rgba(0, 0, 0, 0.3);
                    padding: 6px 12px;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover{
                        filter: brightness(1.2);
                    }                    
                    &.selected {
                        color: #fff;
                        background-color: var(--module-color);
                    }
                }
            }
        }
    }
    .wizard-actions-container {
        height: 28px;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
    }
}