.brand-inits-modal {
  .modal-content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0;
    border-radius: 0;
    border: 0;
    box-shadow: none;

    .modal-body {
      .fields {
        height: 100%;
        padding: 10px;
      }
    }
  }
}