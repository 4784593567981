.refund-modal {
    text-align: center;
    .modal-body {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        .col-left {
            max-width: 150px;
        }
        .col-right {
            max-width: 250px;
        }
        .textField-root {
            margin-bottom: 1em;
        }
    }
}