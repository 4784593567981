.vip-transactions-root {
    padding: 2em;
    height: 100%;
    overflow: hidden;
    .vip-transactions-actions {
        margin: 0 auto 2em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn {
            margin: 0 0 0 1em;
        }
    }
    .vip-transactions-table {
        height: calc(100% - 4em);
    }
}