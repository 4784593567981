.table-info-box-root {
    width: 450px;
    height: 400px;
    .input-switch-root {
        width: 190px;
        padding: 0 0.5em;
        margin: 2em 1em 0;
    }
    .textField-root {
        margin: 1em;
        &.uuid-field {
            width: calc(100% - 2em);
        }
    }
    .qr-code-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1em;
    }
    .qr-code-link {
        width: 330px;
        overflow: hidden;
    }
}