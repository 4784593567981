.open-hours-editor {
    border: 1px solid var(--ipanel-border);
    border-radius: 5px;
    padding: 6px;
    display: flex;
    overflow: auto;
    height: 200px;
    user-select: none;
    &.disabled{
        opacity: 0.65;
        &:hover{
            cursor: not-allowed;
        }
        .time-cell{
            cursor: not-allowed;
            &:hover{
                border: unset;
            }   
        }
    }
    .cell {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .head-row {
        display: flex;
    }
    .time-row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--ipanel-border);
        border-right: 1px solid var(--ipanel-border);
        height: 122px;
    }
    .time-cell {
        cursor: pointer;
        border-top: 1px solid var(--ipanel-border);
        border-left: 1px solid var(--ipanel-border);
        &.selected {
            background: var(--button-blue);
            color: #fff;
            border-color: var(--button-blue);
        }
        &:hover {
            border: 1px solid var(--button-blue-hover);
        }
    }
}