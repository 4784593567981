.fruit-picker-modal {
    .modal-body {
        height: 454px;
    }
    .search-container {
        display: flex;
        align-items: center;
        height: 54px;
        .tab-selector {
            background: rgba(0, 0, 0, 0.09);
            cursor: pointer;
            padding: 1em 2em;
            margin: 0 0.5em;
            border-radius: 5px;
            &.selected {
                background: rgba(0, 0, 0, 0.3)
            }
        }
    }
    .tabs {
        height: 400px;
        overflow: auto;
        padding: 1em;
        .item-container {
            margin: 0.25em 0;
            padding: 0.5em;
            cursor: pointer;
            &:hover {
                background: rgba(0, 0, 0, 0.3)
            }
        }
    }
}