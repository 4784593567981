.user-modal-toggler {
  position: absolute;
  right: 12px;
  top: 0;
  z-index: 500;
  cursor: pointer;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  .svg-root {
    .svgFill {
      fill: #fff;
    }
  }
}
.modal.user-modal {
  .modal-content {
    position: absolute;
    top: 48px;
    right: 26px;
    min-height: 450px;
    width: 300px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
    overflow: auto;
    border: 1px solid var(--ipanel-border);
    .bottom-border {
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 0px;
      width: 95%;
      border-bottom: 3px solid var(--ipanel-border);
    }
    .user-modal {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow: hidden;
      .user-data {
        text-align: center;
        .user-name {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .user-email {
          font-size: 12px;
          margin-bottom: 6px;
        }
        .user-roles {
          display: flex;
          gap: 6px;
          margin-top: 3px;
          justify-content: center;
          .role {
            background-color: var(--main-box-color);
            color: var(--ipanel-main-text-color);
            padding: 0.4em 1em;
            border-radius: 10px;
          }
        }
      }
      .top-action-btns {
        display: flex;
        padding-bottom: 1em;
        position: relative;
        justify-content: center;
        gap: 10px;
        .brand-selector {
          display: flex;
          flex: 1 1 auto;
          background-color: var(--main-box-color);
          transition: all 0.3s;
          justify-content: center;
          align-items: center;
          div[class*="control"] {
            width: 100%;
          }
        }
        .language-selector {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--main-box-color);
          padding: 0px 0.4em;
          border-radius: 6px;
          transition: all 0.3s;
          &:hover {
            filter: brightness(1.2);
          }
        }
      }
      .action-btns {
        position: relative;
        display: flex;
        gap: 12px;
        align-items: center;
        padding-bottom: 12px;
        flex-wrap: wrap;
        justify-content: center;
        .action-btn {
          background-color: var(--main-box-color);
          width: 80px;
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4px;
          flex-shrink: 0;
          color: var(--ipanel-main-text-color);
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s;
          box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
          position: relative;
          .okeoke-loading-screen {
            position: relative;
            border-radius: 5px;
            .svg-root {
              width: 40px;
              height: 40px;
            }
          }
          &:hover {
            filter: brightness(1.2);
          }
          .svg-root {
            .svgFill {
              fill: var(--ipanel-main-text-color);
            }
          }
          &.selected {
            background-color: var(--module-color);
            color: var(--panel-text-color);
            box-shadow: 0px 0px 6px var(--module-color);
            .svg-root {
              .svgFill {
                fill: white;
              }
            }
          }
        }
        .help-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.inactive {
            filter: grayscale(1);
          }
        }
        .dark-mode-icon {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .rebuild-icon {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      .nav-container {
        display: none;
      }
      .logout-container {
        margin-top: auto;
        display: flex;
        align-items: center;
        width: 100%;
        .logout-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 5px 10px;
          gap: 6px;
          margin: 6px;
          background-color: var(--main-box-color);
          border-radius: 5px;
          transition: all 0.3s;
          cursor: pointer;
          box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
          &:hover {
            filter: brightness(1.2);
          }
          .svg-root {
            .svgFill {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.modal {
  .modal-content {
    .language-container {
      display: flex;
      align-items: center;
      gap: 4px;
      color: white;
      padding: 0.6em;
      user-select: none;
      .flag-container {
        display: flex;
        text-align: center;
      }
      &:hover {
        cursor: pointer;
        background-color: var(--inputField-highlight);
      }
      .language-name {
        color: var(--ipanel-main-text-color);
      }
    }
  }
}
