.input-switch-root {
  display: flex;
  user-select: none;
  &.disabled {
    .checkbox-container {
      .checkbox {
        opacity: 0.6;
      }
    }
    .switch-container {
      .slider {
        opacity: 0.6;
      }
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .label {
      width: calc(100% - 1.7em);
      overflow: hidden;
    }
    .checkbox {
      width: 1.2em;
      height: 1.2em;
      padding: 0.1em;
      margin-left: 0.5em;
      background: var(--lightgrey2);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 0.8em;
        height: 0.8em;
        .svgStroke {
          stroke: #fff;
        }
      }
    }
    .checkbox-on {
      background: var(--button-black);
    }
  }
  .switch-container {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: space-between;
    cursor: pointer;
    .label {
      overflow: hidden;
    }
    .slider {
      min-width: 2.5em;
      min-height: 1.2em;
      border-radius: 50px;
      position: relative;
      border: 1px solid var(--ipanel-main-text-color);
      &.switch-on {
        transition: 0.2s;
        background-color: var(--main-box-color);
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
      }
      &.switch-off {
        transition: 0.2s;
        background-color: var(--button-black);
      }
      .thumb {
        width: 0.85em;
        height: 0.85em;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100%;
        transition: 0.2s;
        &.thumb-on {
          background: var(--ipanel-main-text-color);
          left: calc(2.5em - 0.9em - 4px);
          transition: all 0.2s;
        }
        &.thumb-off {
          background: var(--main-box-color);
          left: 2px;
          right: auto;
          transition: all 0.2s;
        }
      }
    }
  }
}

.input-switch-button-container-root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ipanel-button-background-color);
  color: var(--ipanel-main-text-color);
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  user-select: none;
  cursor: pointer;
  transition: all 0.2s;
  &.full-width {
    width: 100%;
  }
  &.full-height {
    height: 100%;
  }
  &:hover {
    filter: brightness(1.2);
  }
  &.checked {
    color: white;
    background-color: var(--module-color);
    box-shadow: 0px 0px 10px var(--module-color);
  }
}

.dark-mode {
  .input-switch-root {
    .switch-container {
      .slider {
        .thumb {
          &.thumb-off {
            background: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}
