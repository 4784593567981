.view-root.open-hours {
    .view-content {
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        overflow: auto;
        .location-card {
            margin: 0 2em 1em 0;
            padding: 0;
            background: var(--main-box-color);
            border-radius: 5px;
            width: 230px;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            .location-open-state {
                color: #fff;
                width: 120px;
                text-align: center;
                padding: 3px 0;
                width: 100%;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 12px;
                &.open {
                    background: var(--button-green);
                    &.warning {
                        background: var(--button-orange);
                    }
                }   
                &.closed {
                    background: var(--button-red)
                }                
            }
            .card-header {
                cursor: pointer;
                margin-top: 2em;
                .location-icon {
                    font-size: 36px;
                    display: flex;
                    justify-content: center;
                    .svg-root {
                        height: 2em;
                        width: 2em;
                    }
                }
                .location-name {
                    font-size: 20px;
                    display: flex;
                    text-align: center;
                }
            }
            .card-body {
                margin-bottom: 3em;
                overflow: auto;
                .location-open-hours, .location-closed-until {
                    display: flex;
                    padding: 0 12px;
                    .label {
                        margin-right: 6px;
                    }
                }
                .location-open-hours {
                    height: 2.5em;
                    margin-top: 3px;
                }
            }
            .close-button-container {
                width: 80%;
                .btn {
                    padding: 0.5em 1em;
                    height: 3em;
                    margin-bottom: 1em;
                    width: 100%;
                    .btn-text {
                        text-transform: uppercase;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.location-open-hours-modal {
    .textField-root {
        margin-right: 0.5em;
    }
}