.adjust-vip-points-modal {
    .modal-body {
        .message {
            max-width: 500px;
        }
        .operations-container {
            display: flex;
            justify-content: space-between;
        }
    }
}