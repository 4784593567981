$accordion-root-height: 2.5em;
$accordion-root-paddingX: 0.5em;
$accordion-root-paddingY: 0.5em;

.accordion-root {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .accordion-header {
        color: var(--ipanel-main-text-color);
        position: relative;
        padding: $accordion-root-paddingY $accordion-root-paddingX;
        border: 1px solid var(--ipanel-border);
        border-left: none;
        border-right: none;
        overflow: hidden;
        height: $accordion-root-height;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        .accordion-expandIcon {
            position: absolute;
            right: $accordion-root-paddingX;
            top: 50%;
            height: 1.5em;
            width: 1.5em;
        }
        .accordion-header-icon {
            margin-right: 0.5rem;
            height: 2rem;
        }
        .accordion-loading {
            position: absolute;
            right: $accordion-root-paddingX;
        }
        .sub-label {
            font-weight: normal;
        }
    }
    .accordion-header.accordion-open {
        border-bottom: none;
    }
    .accordion-body {
        background-color: var(--main-box-color);
        color: var(--ipanel-main-text-color);
        padding: 0.5em;
        border-radius: 5px;
        max-height: calc(100vh - 9em - 1em - #{$accordion-root-height});
        overflow: auto;
        position: relative;
        width: 100%;
    }
}
.accordion-root.accordion-open {
    max-height: calc(100vh - 9em);
    transition: max-height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    .accordion-header {
        .accordion-expandIcon {
            svg {
                transform: translateY(-50%) rotate(0deg);
                transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
        }
    }
}
.accordion-root.accordion-closed {
    max-height: $accordion-root-height;
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    .accordion-header {
        .accordion-expandIcon {
            svg {
                transform: translateY(-50%) rotate(180deg);
                transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
        }
    }
}