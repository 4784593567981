.modal.datePicker-modal {
    .modal-bgLayout {
        background: none;
    }
    .modal-content {
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
        .rdrDateRangePickerWrapper {
            margin-top: 1em;
        }
        .date-picker-actions {
            position: absolute;
            top: 1em;
            right: 1em;
            display: flex;
            align-items: center;
            .btn {
                margin-left: 1em;
            }
        }
    }
}