.modal.dictionary-edit-modal {
    .modal-content {
        .modal-header {
            .modal-title {
                width: 100%;
                font-size: 16px;
                overflow-wrap: break-word;
                overflow: hidden;
                padding: 0;
            }
        }
    }
}
.view-root.global-dictionary {
    .view-actions {
        flex-wrap: wrap;
        padding: 6px;
        flex: none;
        padding: 0;
        .actions-left {
            flex-wrap: wrap;
            gap: 6px;
            .search-field {
                width: 65%;
            }
            .limit-field {
                width: 30%;
            }
        }
    }
}