@keyframes float-select {
    0% {
        margin-bottom: 0;
    }
    100% {
        margin-bottom: 25px;
    }
}
@keyframes land {
    0% {
        margin-bottom: 25px;
    }
    100% {
        margin-bottom: 0px;
    }
}
@keyframes rotate {
    0% {
        rotate: 0;
    }
    100% {
        rotate: 90deg;
    }
}