.input-field-main-container{
    position: relative;
    
    .title{
        position: absolute;
        font-family: Poppins;
        top: -23px;
        left: 1px;
        height: 23px;
        user-select: none;
    }
    .text-box{
        display: flex;
        align-items: center;
        height: 53px;
        background-color: #EDEDED;
        border-radius: 5px;
        padding: 0px 12px;
        font-family: Poppins;
        line-height: 22px;
        border-style: none;
        background-color: var(--inputField-background-color);
        color: var(--text-color);
        border-color: var(--text-color);
        &.dotted{
            border-style: dashed;
            border-width: 1px;
            background-color: white;
        }
    }
    .error-row{
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        line-height: 22px;
        font-family: Poppins;
        color: #EC5252;
        white-space: nowrap;
    }
}
