.billing-root {
  .view-content {
    display: flex;
    .brand-list {
      min-width: max-content;
      overflow: auto;
      padding: 6px 0;
      .brand-btn {
        border: 1px solid var(--ipanel-border);
        border-radius: 5px;
        margin: 0 6px 6px 0;
        padding: 6px 12px;
        background-color: var(--main-box-color);
        cursor: pointer;
        transition: all 0.3s;
        min-width: 250px;
        &:hover {
          background-color: var(--main-box-color-hover);
        }
        &.selected {
          background-color: var(--button-blue);
          color: #fff;
        }
        &.loading {
          min-width: 250px;
          min-height: 31px;
        }
      }
    }
    .selected-brand {
      flex-grow: 1;
      padding: 0 12px 12px;
      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 12px;
      }
      .box {
        margin: 0 0 24px 0;
        padding: 12px;
        border: 1px solid var(--ipanel-border);
        border-radius: 5px;
        max-width: max-content;
        .box-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 12px;
        }
      }
      .module {
        display: flex;
        gap: 6px;
        align-items: center;
        width: 300px;
        &.switch {
          width: 350px;
        }
        .name {
          flex-grow: 1;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          .svg-root {
            &.check {
              padding: 3px;
              .svgStroke {
                stroke: var(--button-green);
              }
            }
            &.cross {
              .svgFill {
                fill: var(--button-red);
              }
            }
          }
        }
      }
    }
  }
}
