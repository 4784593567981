.template-editor-root {
    .template-editor-actions {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        .template-name {
            flex-grow: 1;
        }
        .btn {
            margin-left: 1em;
        }
    }
    .template-data {
        display: flex;
        background: rgb(249, 249, 249);
        .template-meta-data {
            padding: 1em;
            min-width: 400px;
            max-width: 400px;
            .textField-root {
                margin-bottom: 1em;
            }
        }
    }
}