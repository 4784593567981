.create-user-modal {
  .modal-content {
    .modal-body {
      .user-options {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .option {
          display: flex;
          transition: all 0.3s;
          &:hover{
            cursor: pointer;
            filter: brightness(1.1)
          }
          .name {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100px;
            height: 100px;
            font-weight: 600px;
            background-color: var(--module-color);
            color: white;
            flex-wrap: wrap;
            border: 2px solid var(--ipanel-border);
            border-right-width: 1px;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }
          .desc {
            height: 100px;
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 2px solid var(--ipanel-border);
            border-left-width: 1px;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
}
