.brands-editor {
 .header {
    .title {
        
    }
 }   
}
.commission-root {
    display: flex;
    flex-direction: column;
    .sum-container {
        margin-bottom: 6px;
        flex: 0 0 2em;
        font-size: 20px;
    }
}
.brand-commissions-root {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    flex: 1 1 auto;
    overflow: auto;
    .brand-card {
        width: 160px;
        height: 180px;
        background: var(--main-box-color);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin: 0 12px 12px 0;
        opacity: 0.7;
        transition: opacity 200ms ease-in-out;
        &:hover {
            opacity: 1;
            transition: opacity 200ms ease-in-out;
        }
        .header {
            height: 22px;
            font-size: 12px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            &.online {
                background: var(--button-green);
            }
            &.offline {
                background: var(--button-red);
            }
        }
        .body {
            flex: 1 1 auto;
            padding: 12px;
            overflow: auto;
            text-align: center;
            .brand-name {
                font-size: 18px;
                margin-bottom: 8px;
                height: 44px;
                overflow: hidden;
            }
            .label {
                font-size: 12px;
            }
            .value {
                font-size: 18px;
                font-weight: bold;
            }
            .total-devices {
                overflow: hidden;
            }
            .total-commissions {
                overflow: hidden;
            }
        }
        .footer {
           background: rgba(0, 0, 0, 0.25);
           font-size: 12px;
           padding: 4px;
           text-align: right; 
        }
    }
}
.view-content.selected-brand {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    width: 100%;
    .info-box {
        width: 360px;
        padding: 1em;
        background: var(--main-box-color);
        margin: 0 1em 1em 0;
        border-radius: 5px;
        overflow: hidden;
        overflow-y: auto;
        .textField-root {
            margin: 0 1em 1em 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .image-upload-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.brand-devices-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .device-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
      }
}