.coupon-condition-frame {
  width: 90vw;
  height: 90vh;
  padding: 0px 75px 0px 75px;
  border-radius: 20px;
  .header {
    border-bottom-style: solid;
    border-bottom-color: #cccccc;
    border-bottom-width: 1px;
    display: flex;
    height: 69px;
    align-items: flex-end;
    width: 100%;
    position: relative;
    .text-conditions {
      margin-left: 5px;
      font-family: Poppins;
      font-weight: bold;
      line-height: 38px;
    }
    .text-open-all {
      margin-left: 46px;
      font-family: Poppins;
      line-height: 38px;
    }
    .text-select-all {
      margin-left: 46px;
      font-family: Poppins;
      line-height: 38px;
    }
    .button-container {
      display: flex;
      align-self: center;
      position: absolute;
      right: 13px;
      .save {
      }
      .cancel {
        margin-left: 14px;
      }
    }
  }
  .content-container {
    display: flex;
    margin-top: 7px;
    .first-column {
      width: 50%;
      .first-row-header {
        display: flex;
        height: 52px;
        .lightbulb {
          margin-left: 3px;
        }
        .text {
          margin-left: 14px;
          font-family: Poppins;
          text-align: center;
          line-height: 22px;
        }
      }
      .second-row {
        display: flex;
        height: 52px;
      }
      .first-column-context{
        width: 100%;
        .second-row {
            display: flex;
            width: 100%;
            .text{
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              line-height: 37px;
              width: 50%;
            }
          }
      }
    }
    .second-column {
      width: 50%;
      .first-row-header {
        margin-left: 35px;
        display: flex;
        height: 52px;
        .lightbulb {
        }
        .text {
          margin-left: 14px;
          font-family: Poppins;
          text-align: center;
          line-height: 22px;
        }
      }
      .second-column-context {
        width: 100%;
        border-left-style: solid;
        border-left-color: #cccccc;
        border-left-width: 1px;
        .second-row {
          margin-left: 34px;
          display: flex;
          width: 100%;
          .text{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            line-height: 37px;
            width: 50%;
          }
        }
      }
    }
  }
}
