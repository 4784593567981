.downloads-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #252836;
    color: #fff;
    .card {
        background: #1f1d2b;
        width: 150px;
        height: 250px;
        margin: 12px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px;
        opacity: 0.7;
        transition: 0.2s all ease-in-out;
        h3 {
            margin: 6px 0 0 0;
            text-align: center;
        }
        p {
            margin: 0 0 6px 0;
            text-align: center;
            font-size: 12px;
        }
        .img-container {
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        a {
            color: #fff;
        }
        .btn {
            cursor: pointer;
            background: #ea7c69;
        }
        &:hover {
            opacity: 1;
        }
    }
}