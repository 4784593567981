body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}
.font-smallest {
    font-size: 10px;
}
.font-small {
    font-size: 12px;
}
.font-normal {
    font-size: 14px;
}
.font-large {
    font-size: 16px;
}
.font-extraLarge {
    font-size: 19px;
}
.font-500{
    font-weight: 500;
}
.font-600{
    font-weight: 600;
}
.font-700{
    font-weight: 700;
}
.font-bold {
    font-weight: bold;
}
.text-center {
    text-align: center;
}
.font-uppercase {
    text-transform: uppercase;
}