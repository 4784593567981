html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
main {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hidden {
  opacity: 0;
}
.opacity-03 {
  opacity: 0.3;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-08 {
  opacity: 0.8;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.appear-quick {
  animation: 0.3s appear cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
.appear {
  animation: 1s appear forwards;
}
.appear-slow {
  animation: 2s appear forwards;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
textarea:focus,
input:focus {
  outline: none;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickable {
  cursor: pointer;
  height: 100%;
  min-height: 1em;
  min-width: 30px;
  display: inline-flex;
  align-items: center;
  &.mobile {
    display: none;
  }
}
.dictionary-add {
  background: var(--module-color);
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.anchor{
  position: relative
}
table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
th,
td {
  padding: 0.35em 0.75em;
}
td {
  overflow: hidden;
  white-space: nowrap;
}
thead {
  font-weight: bold;
}
th {
  background: var(--main-box-color);
  position: sticky;
  overflow: hidden;
  white-space: nowrap;
  top: 0; /* Don't forget this, required for the stickiness */
}
textarea {
  width: 100%;
  height: 136px;
  padding: 26px 12px 10px 12px;
  box-sizing: border-box;
  background: transparent;
  border: none;
  resize: none;
  font-family: "Roboto", sans-serif;
  color: var(--ipanel-main-text-color);
}
.svg-root {
  height: 1.5em;
  width: 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.inactive {
    svg {
      .svgFill {
        opacity: 0.5;
      }
      .svgStroke {
        opacity: 0.5;
      }
    }
  }
  svg {
    width: 100%;
    height: 100%;
    .svgFill {
      fill: var(--ipanel-main-text-color);
    }
    .svgStroke {
      stroke: var(--ipanel-main-text-color);
    }
  }
}
.custom-toast {
  border-radius: 5px !important;
}
#map-container {
  height: 100%;
  border-radius: 5px;
}
.leaflet-control-attribution {
  display: none !important;
}
.drop-shadow-type1 {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25), 5px 5px 10px rgba(0, 0, 0, 0.25);
}
input {
  color: var(--ipanel-main-text-color);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.version-container {
  position: fixed;
  bottom: 1px;
  left: 1px;
  z-index: 999;
  color: var(--panel-text-color);
  font-size: 8px;
  opacity: 0.3;
}
.flex{
  display: flex;
}
.row {
  display: flex;
  width: fit-content;
}
.m-05 {
  margin: 0.5em;
}
.m-1 {
  margin: 1em;
}
.m-2 {
  margin: 2em;
}
.mt-05 {
  margin-top: 0.5em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mr-05 {
  margin-right: 0.5em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.ml-05 {
  margin-left: 0.5em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mx-05 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.mx-1 {
  margin-left: 1em;
  margin-right: 1em;
}
.mx-2 {
  margin-left: 2em;
  margin-right: 2em;
}
.my-05 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.m-05 {
  margin: 0.5em;
}
.p-05 {
  padding: 0.5em;
}
.p-1 {
  padding: 1em;
}
.p-2 {
  padding: 2em;
}
.pt-05 {
  padding-top: 0.5em;
}
.pt-1 {
  padding-top: 1em;
}
.pt-2 {
  padding-top: 2em;
}
.pr-05 {
  padding-right: 0.5em;
}
.pr-1 {
  padding-right: 1em;
}
.pr-2 {
  padding-right: 2em;
}
.pl-05 {
  padding-left: 0.5em;
}
.pl-1 {
  padding-left: 1em;
}
.pl-2 {
  padding-left: 2em;
}
.pb-05 {
  padding-bottom: 0.5em;
}
.pb-1 {
  padding-bottom: 1em;
}
.pb-2 {
  padding-bottom: 2em;
}
.px-05 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}
.px-2 {
  padding-left: 2em;
  padding-right: 2em;
}
.py-05 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}
.overflow-visible{
  overflow: visible;
}
a {
  text-decoration: none;
  color: var(--ipanel-main-text-color);
  &:visited,
  &:active {
    color: var(--ipanel-main-text-color);
  }
}
.max-width-600 {
  max-width: 600px;
}
.qrcode-container {
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  video {
    position: unset;
  }
  .close-icon {
    svg {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      height: 28px;
      width: 28px;
      z-index: 99999;
    }
  }
}
.qrreaderview {
  pointer-events: none;
  position: absolute;
  top: 0px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  svg {
    width: 70%;
    height: 70%;
    .svgStroke {
      stroke: white;
    }
  }
}
.new-version-available {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  text-align: center;
  background-color: var(--button-orange);
  color: #fff;
  cursor: pointer;
}
