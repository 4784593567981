.input-field-multi-select-main-container.textField-root {
  position: relative;
  min-width: 165px;
  .textField-base {
    cursor: pointer;
  }
  .title {
    position: absolute;
    font-family: Poppins;
    top: -23px;
    left: 1px;
    height: 23px;
  }
  .select-box {
    display: flex;
    align-items: center;
    height: 2em;
    background-color: #ededed;
    border-radius: 5px;
    padding: 0px 12px;
    font-family: Poppins;
    line-height: 22px;
    font-weight: 500;
    border-style: none;
    border-color: var(--text-color);
  }
  .error-row {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-family: Poppins;
    color: #ec5252;
    white-space: nowrap;
  }
  .select-panel {
    display: flex;
    align-items: center;
    height: 2em;
    padding: 0.5em 1em;
    cursor: pointer;
    border-radius: 5px;
    // background-color: #ededed;
    list-style: none;
    font-weight: 500;
    font-family: Poppins;
    user-select: none;
    background-color: var(--inputField-background-color);
    color: var(--text-color);
  }

  .list {
    width: 95%;
    background: #f5f5f5;
    position: absolute;
    // padding: 0px 0px 23px;
    margin: 0 2.5%;
    box-sizing: border-box;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 2;
    background-color: var(--inputField-background-color);
    color: var(--text-color);
  }

  label {
    width: 100%;
    display: block;
    cursor: pointer;
  }
}

.okeoke-input-field-multi-select-selection-table {
  background-color: var(--main-box-color);
  border-radius: 5px;
  .list-element {
    display: flex;
    height: 3em;
    padding: 0 0.6em;
    align-items: center;
    user-select: none;
    position: relative;
    cursor: pointer;
    color:var(--ipanel-main-text-color);
    &.selected{
      color:var(--module-color);
    }
    .label {
      white-space: nowrap;
      font-weight: 500;
      font-family: Poppins;
      cursor: pointer;
    }
    .svg-container {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 1em;
      }
    }
    &:hover {
      background-color: var(--inputField-highlight);
    }
  }
}

.InputFieldMultiSelect-modal {
  &.mobile.modal {
    backdrop-filter: blur(2px) brightness(0.8);
  }
  &.modal {
    .modal-bgLayout {
      background: none;
    }
    .modal-content {
      position: absolute;
      padding: unset;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
      max-height: 400px;
      overflow: auto;
      border: 1px solid var(--ipanel-borde);
    }
  }
}
