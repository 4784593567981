.welcome-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .svg-root {
    display: flex;
    width: 300px;
    height: 100px;
    svg {
      .svgFill {
        fill: var(--module-color);
      }
      .svgStroke {
        stroke: var(--module-color);
      }
    }
  }
  .row {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 18px;
    &.large {
      font-size: 34px;
      font-weight: 600;
    }
  }
  .page-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    width: 50%;
    min-width: 300px;
    .page-button {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      justify-content: center;
      align-items: center;
      width: 30%;
      font-size: 18px;
      font-weight: 600;
      aspect-ratio: 1/1;
      overflow: hidden;
      background-color: var(--main-box-color);
      &:hover{
        cursor: pointer;
      }
      .svg-root{
        height: 30%;
        width: 30%;
      }
      .fit-loading-svg{
        .svg-root {
          width: 50px;
        }
      }
    }
  }
}
