.login-screen {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  .left-side {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      max-width: 400px;
      border-radius: 5px;
      border: 3px solid #fff;
    }
  }
  .right-side {
    height: 100%;
    width: 50%;
    background-color: var(--main-background-color);
    color: var(--ipanel-main-text-color);
    .text {
      text-align: center;
      user-select: none;
    }
    .text-large {
      margin-top: 64px;
      font-weight: 1000;
      font-size: 5em;
    }
    .text-normal {
      font-weight: 600;
      font-size: 2.5em;
    }
    .login-fields {
      margin-top: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .textField-root {
        margin-bottom: 3em;
        width: 70%;
        max-width: 400px;
      }
      .react-code-input {
        width: 70%;
        max-width: 400px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 56px;
        margin-bottom: 3em;
        input {
          margin: 0 10px !important;
          height: 60px !important;
          width: 52px !important;
          font-size: 32px !important;
          padding-left: 12px !important;
        }
      }
    }
  }
  .language-selector {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
.registration {
  .clickable {
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
  }
}
