.okeoke-data-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--main-box-color);
  border-radius: 5px;
  padding: 0.5em 0.5em 1.2em 0.5em;
  color: var(--ipanel-main-text-color);
  .message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-decoration: underline;
    cursor: pointer;
  }
  .data-table-coloum-default{
    width:unset;
    font-size: 1em;
    text-decoration: unset;
    display: table-cell;
  }
  .data-table-inner {
    overflow: auto;
    flex-grow: 1;    
  }
  .data-table {
    width: 100%;
    thead {
      tr {
        th {
          user-select: none;
          z-index: 20;
          &.sortable {
            cursor: pointer;
          }
          &.head-align-right {
            text-align: right;
          }
          &.head-align-left {
            text-align: left;
          }
          &.head-align-center {
            text-align: center;
          }
          .sort-icon {
            height: 14px;
            position: absolute;
            right: 3px;
            svg {
              height: 14px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
        td {
          border-bottom: 1px solid var(--ipanel-border);
          &:nth-child(2) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          svg {
            cursor: pointer;
          }
          &.cell-align-right {
            text-align: right;
          }
          &.cell-align-left {
            text-align: left;
          }
          &.cell-align-center {
            text-align: center;
            .clickable {
              justify-content: center;
            }
          }
        }
      }
      .under-row-container {
        &.under-row-container-hidden {
          display: none;
        }
      }
    }
  }
  .data-cell {
    max-width: 200px;
    white-space: break-spaces;
	  text-overflow: ellipsis;
    &.tight {
      max-width: 80px;
    }
    .svg-root {
      margin: 0 3px;
    }
    &.checkbox-cell {
      width: 45px;
    }
    &.middle{
      text-align: center;
    }
  }
  .data-cell.dnd {
    cursor: grab;
    svg {
      cursor: grab;
    }
  }
  .pagination-container {
    user-select: none;
    width: 100%;
    height: 2em;
    display: flex;
    place-content: center;
    align-items: center;
    .pagination-inner {
      width: 216px;
      font-size: 1.2em;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .pagination-button {
      width: 24px;
      height: 24px;
      display: flex;
      place-content: center;
      cursor: pointer;
      &.selected {
        font-weight: bold;
      }
    }
  }
}