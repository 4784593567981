.flag-modal {
    .svg-container {
        height: 3em;
        width: 4em;
        justify-content: center;
    }
}
.flag-selector-container {
    width: 4em;
    height: 3em;
    justify-content: center;
}