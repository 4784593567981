.btn {
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--ipanel-button-background-color);
  color: var(--ipanel-main-text-color);
  border-radius: 5px;
  font-size: 1em;
  font-weight: 600;
  border: 1px solid var(--ipanel-border);
  cursor: pointer;
  padding: 3px 12px;
  font-family: Poppins;
  min-width: fit-content;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  &.full-width {
    width: 100%;
  }
  .svg-root {
    svg {
      .svgFill {
        transition: 0.3s;
        fill: var(--ipanel-button-text-color);
      }
      .svgStroke {
        transition: 0.3s;
        stroke: var(--ipanel-button-text-color);
      }
    }
  }
  .btn-text {
    //min-width: 80px;
    //display: flex;
    width: max-content;
    /*overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;*/
  }
  &:hover {
    filter: brightness(1.25);
    transition: 0.3s;
    .svg-root {
      svg {
        .svgFill {
          transition: 0.3s;
          fill: var(--button-hover-text-color);
        }
        .svgStroke {
          transition: 0.3s;
          stroke: var(--button-hover-text-color);
        }
      }
    }
  }
  &.with-svg {
    padding-left: 30px;
    &:hover {
      .svg-root {
        .svgFill {
          fill: var(--button-hover-text-color);
        }
        .svgStroke {
          stroke: var(--button-hover-text-color);
        }
      }
    }
    .svg-container {
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5em;
      width: 18px;
      height: 18px;
    }
    .svg-root {
      .svgFill {
        fill: var(--ipanel-button-text-color);
      }
      .svgStroke {
        stroke: var(--ipanel-button-text-color);
      }
    }
  }
}
.btn-transparent {
  background: transparent;
  border: transparent;
}
.btn.btn-big {
  font-size: 24px;
  width: 70%;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  height: 60px;
  max-width: 400px;
  border-color: transparent;
  color: #fff;
}
.btn.btn-black {
  background: var(--button-black);
  color: #fff;
  border-color: transparent;
  &.with-svg {
    .svg-root {
      svg {
        .svgStroke {
          stroke: #fff;
        }
        .svgFill {
          fill: #fff;
        }
      }
    }
  }
  &:hover {
    color: #fff;
    background: #494a4d;
    &.with-svg {
      .svg-root {
        svg {
          .svgStroke {
            stroke: #fff;
          }
          .svgFill {
            fill: #fff;
          }
        }
      }
    }
  }
}
.btn.btn-red {
  background: var(--button-red);
  color: #fff;
  border-color: transparent;
  &.with-svg {
    .svg-root {
      svg {
        .svgStroke {
          stroke: #fff;
        }
        .svgFill {
          fill: #fff;
        }
      }
    }
  }
  &:hover {
    background: var(--button-red-hover);
  }
}
.btn.btn-green {
  background: var(--button-green);
  color: #fff;
  border-color: transparent;
  &.with-svg {
    .svg-root {
      svg {
        .svgStroke {
          stroke: #fff;
        }
        .svgFill {
          fill: #fff;
        }
      }
    }
  }
  &:hover {
    background: var(--button-green-hover);
  }
}
.btn.btn-blue {
  background: var(--button-blue);
  color: #fff;
  border-color: transparent;
  &.with-svg {
    .svg-root {
      svg {
        .svgStroke {
          stroke: #fff;
        }
        .svgFill {
          fill: #fff;
        }
      }
    }
  }
  &:hover {
    background: var(--button-blue-hover);
  }
}
.btn.btn-orange {
  background: var(--button-orange);
  color: #fff;
  border-color: transparent;
  &.with-svg {
    .svg-root {
      svg {
        .svgStroke {
          stroke: #fff;
        }
        .svgFill {
          fill: #fff;
        }
      }
    }
  }
  &:hover {
    background: var(--button-orange-hover);
  }
}
.btn.btn-lightblue {
  background: var(--button-lightblue);
  color: #000;
  border-color: transparent;
  &.with-svg {
    .svg-root {
      svg {
        .svgStroke {
          stroke: #000;
        }
        .svgFill {
          fill: #000;
        }
      }
    }
  }
  &:hover {
    background: var(--button-lightblue-hover);
    color: #000;
  }
}
.btn-orange-gradient {
  background: var(--button-orange-gradient);
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}
.btn-green-gradient {
  background: var(--button-green-gradient);
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}
.btn-blue-gradient {
  background: var(--button-blue-gradient);
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}
.btn-black-gradient {
  background: var(--button-black-gradient);
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}
.btn-red-gradient {
  background: var(--button-red-gradient);
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}
.btn.btn-yes {
  background: var(--button-yes);
  color: #fff;
  border-color: transparent;
  &.with-svg {
    .svg-root {
      svg {
        .svgStroke {
          stroke: #fff;
        }
        .svgFill {
          fill: #fff;
        }
      }
    }
  }
  &:hover {
    background: var(--button-yes-hover);
  }
}
.btn.btn-no {
  background: var(--button-no);
  border-color: transparent;
  &:hover {
    background: var(--button-no-hover);
  }
}
.btn.disabled {
  cursor: not-allowed;
  border-color: transparent;
  filter: brightness(0.75);
}
.btn.loading {
  .svg-container {
    margin: 0;
    top: 25%;
  }
}
.btn.btn-small {
  font-size: 10px;
  border: none;
}

.btn-vip-color {
  background: var(--ipanel-vip-color);
  color: white;
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-global-color {
  background: var(--ipanel-global-color);
  color: white;
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-reseller-color {
  background: var(--ipanel-reseller-color);
  color: white;
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-brand-color {
  background: var(--ipanel-brand-color);
  color: white;
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-module-color {
  background:var(--module-color);
  color: var(--panel-text-color);
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.2);
  }
}