.simple-table-root {
    .simple-table-title {
        text-align: center;
    }
    .simple-table {
        background-color: var(--lightgrey);
        padding: 1em;
        border-radius: 5px;
        .simple-table-head, .simple-table-row {
            display: flex;
            align-items: center;
            margin: 0 0 1em 0;
        }
        .simple-table-row:last-child {
            margin-bottom: 0;
        }
    }
}