.no-permission {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    img {
        border-radius: 5px;
        width: 40%;
    }
}