.ntak-selector-root{
    display: flex;
    .selector{
        display: flex;
        flex-direction: column;
        width: 250px;
        .name{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
}